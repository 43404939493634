import {
  Alert,
  Body,
  Footer,
  Header,
  DevelopmentInfo,
  useAlerts,
  useUsers,
} from "common";
import { useTranslation } from "react-i18next";
import { MainContainer } from "common/components/Main-Container/MainContainer";
import { LicenseInfo as MuiLicenseInfo } from "@mui/x-license";
import ReactGA from "react-ga4";

export const App = () => {
  const { alert } = useAlerts();
  const { t } = useTranslation();
  const { loggedInUser, currentUser, accessDenied } = useUsers();

  MuiLicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

  if (currentUser.isLoaded && !accessDenied) {
    ReactGA.initialize(window.GA_MEASUREMENT_ID);
  }

  return (
    <>
      <DevelopmentInfo />
      <Header
        logoTitle={t("app.logoTitle")}
        logoSubtitle={t("app.logoSubtitle")}
        userDisplayName={loggedInUser.displayName}
      />
      <Body>
        {currentUser.isLoaded ? (
          <>
            {alert.exists && <Alert />}
            <MainContainer idAttr="main" />
          </>
        ) : (
          alert.exists && <Alert />
        )}
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        productVersion={window.PRODUCT_VERSION || process.env.REACT_APP_VERSION}
      />
    </>
  );
};
