import { useState, useEffect } from "react";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Lens as MuiLensIcon,
  Help as MuiHelpIcon,
  Edit as MuiEditIcon,
  Delete as DeleteIcon,
  ListAlt as MuiListAltIcon,
  CropSquare as MuiCropSquareIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
/** ENUM import **/
import { LeaveTypes, SegmentsColor } from "app/shared/constants";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { ViewEditIndividualMilestone } from "./Milestone/ViewEditIndividualMilestone";
import { convertDateStrToFLADateStr } from "app/shared/utils";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
/** Service Call **/
import { getAppointmentSegments } from "app/services/leaveAccrualTakenService";
import { deleteIndividualMilestone } from "app/services/individualMilestoneService";

//Function to create the Appointment Segments panel
export const AppointmentSegments = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const appointmentSegmentsLabels =
    "LeaveAccruedTaken.appointmentSegmentsLabels";
  const [appointmentSegments, setAppointmentSegments] = useState([]);
  /* Function to format the Decimal Values,
   * if the length of Decimal digits is greater than 3,
   * then round-off the value to 3 decimal places (keeping the trailing zeros),
   * otherwise return the same value
   */
  const formatDecimalValues = (value) =>
    value % 1 !== 0 && value.toString().split(".")[1].length > 3
      ? value.toFixed(3)
      : value;
  // State for Edit, View Dialog
  const [openEditViewDialog, setOpenEditViewDialog] = useState(false);
  // State for Delete Message
  const [showDeleteConfirmationMsg, setShowDeleteConfirmationMsg] =
    useState(false);
  // State for Milestone Id
  const [milestoneId, setMilestoneId] = useState();
  const [actionType, setActionType] = useState();
  const {
    universityId,
    reloadAppointmentSegments,
    setReloadAppointmentSegments,
    setReloadAudit,
    setReloadAccrual,
    setAlert,
    clearAlert,
    editMilestone,
    removeMilestone,
    viewMilestone,
  } = props;
  const [loading, setLoading] = useState(false);
  /** Call to service to fetch appointment segments */
  useEffect(() => {
    if (reloadAppointmentSegments || !appointmentSegments.length) {
      getAppointmentSegments(
        setAppointmentSegments,
        setLoading,
        setAlert,
        clearAlert,
        universityId
      );
      setReloadAppointmentSegments(false);
    }
  }, [
    universityId,
    reloadAppointmentSegments,
    appointmentSegments,
    setReloadAppointmentSegments,
    clearAlert,
    setAlert,
  ]);
  /** Delete Event */
  const deleteClickEvent = (milestoneLink) => {
    setMilestoneId(new URL(milestoneLink).pathname.split("/").pop());
    setShowDeleteConfirmationMsg(true);
    setReloadAppointmentSegments(false);
    setReloadAudit(false);
  };
  /** Call to service, on click of 'Yes' button on Delete Msg */
  const handleDelete = (milestoneId) => {
    deleteIndividualMilestone(
      setReloadAppointmentSegments,
      setReloadAudit,
      setReloadAccrual,
      setShowDeleteConfirmationMsg,
      setAlert,
      clearAlert,
      {
        universityId,
        milestoneId,
      }
    );
  };

  const columnData = [
    {
      id: "leaveType",
      value: t(`${appointmentSegmentsLabels}.category`),
      width: "28%",
    },
    {
      id: "startDate",
      value: t(`${appointmentSegmentsLabels}.startDate`),
      width: "10%",
    },
    {
      id: "endDate",
      value: t(`${appointmentSegmentsLabels}.endDate`),
      width: "10%",
    },
    {
      id: "apptFte",
      value: t(`${appointmentSegmentsLabels}.somFte`),
      width: "10%",
    },
    {
      id: "dailyAccrual",
      value: t(`${appointmentSegmentsLabels}.dailyAccrual`),
      width: "12%",
      tooltipHeader: (
        <MuiTypography variant="body2">
          {t(`${appointmentSegmentsLabels}.tooltip.dailyAccrual.text`)}
        </MuiTypography>
      ),
      tooltipIcon: (
        <MuiHelpIcon color="primary" fontSize="small" viewBox="0 0 22 22" />
      ),
    },
    {
      id: "maxAccrual",
      value: t(`${appointmentSegmentsLabels}.maxAccrual`),
      width: "12%",
    },
    {
      id: "actions",
      value: t("globals.labels.actions"),
    },
  ];
  /** Edit, View Icon click event */
  const handleIconClick = (data, actionType) => {
    setOpenEditViewDialog(true);
    setMilestoneId(new URL(data._links.self.href).pathname.split("/").pop());
    setActionType(actionType);
  };

  const rowData = [];

  appointmentSegments.map((data) =>
    rowData.push({
      leaveType: {
        value: data.milestoneType
          ? `${t(`${appointmentSegmentsLabels}.${data.milestoneType}`)} - ${
              data.milestoneDescription
            }`
          : t(LeaveTypes[data.leaveType]),
        fontStyle: data.milestoneType && "italic",
        segmentIcon: data.milestoneType ? (
          <MuiCropSquareIcon
            fontSize="small"
            sx={{
              transform: "rotate(45deg)",
              color: SegmentsColor[`${data.leaveType}_DARK`],
            }}
          />
        ) : (
          <MuiLensIcon
            fontSize="small"
            sx={{ color: SegmentsColor[`${data.leaveType}_LIGHT`] }}
          />
        ),
      },
      startDate: {
        value: convertDateStrToFLADateStr(data.startDate),
        fontStyle: data.milestoneType && "italic",
      },
      endDate: {
        value: convertDateStrToFLADateStr(data.endDate),
        fontStyle: data.milestoneType && "italic",
      },
      apptFte: {
        value: data.apptFte,
        fontStyle: data.milestoneType && "italic",
      },
      accrualRate: {
        value: (data.leaveType === "NA" || data.leaveType === "NONE") && "0.0",
      },
      dailyAccrual: {
        value: data.dailyAccrual && formatDecimalValues(data.dailyAccrual),
        fontStyle: data.milestoneType && "italic",
      },
      maxAccrual: {
        value: data.maxAccrual && formatDecimalValues(data.maxAccrual),
        fontStyle: data.milestoneType && "italic",
      },
      actions: {
        value: data.milestoneType === "INDIVIDUAL" && (
          <>
            {editMilestone && (
              <MuiIconButton
                aria-label="edit"
                onClick={() => handleIconClick(data, "edit")}
              >
                <MuiEditIcon fontSize="small" />
              </MuiIconButton>
            )}
            {viewMilestone && (
              <MuiIconButton
                aria-label="view"
                onClick={() => handleIconClick(data, "view")}
              >
                <MuiListAltIcon fontSize="small" />
              </MuiIconButton>
            )}
            {removeMilestone && (
              <MuiIconButton
                aria-label="delete"
                onClick={() => {
                  deleteClickEvent(data._links.self.href);
                }}
              >
                <DeleteIcon fontSize="small" />
              </MuiIconButton>
            )}
          </>
        ),
      },
      rowColor: {
        value: data.milestoneType,
      },
    })
  );

  return (
    (loading && (
      <MuiGrid container justifyContent="center">
        <MuiCircularProgress />
      </MuiGrid>
    )) || (
      <>
        <AccordionSummaryTable columnData={columnData} rowData={rowData} />
        <ViewEditIndividualMilestone
          open={openEditViewDialog}
          handleCancel={() => setOpenEditViewDialog(false)}
          universityId={props.universityId}
          milestoneId={milestoneId}
          actionType={actionType}
          setReloadAudit={setReloadAudit}
        />
        <ConfirmationDialog
          open={showDeleteConfirmationMsg}
          title={t("LeaveAccruedTaken.individualMilestoneForm.removeMsgTitle")}
          message={t("LeaveAccruedTaken.individualMilestoneForm.removeMsg")}
          handleCancel={() => setShowDeleteConfirmationMsg(false)}
          handleOk={() => {
            handleDelete(milestoneId);
          }}
          okLabel={t("globals.form.actionButtons.yes")}
          cancelLabel={t("globals.form.actionButtons.no")}
        />
      </>
    )
  );
};
