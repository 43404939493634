import axios from "axios";

// Service to save Leave Action
export const saveLeaveAction = async (
  setShowSaveConfirmationMsg,
  setOpenDialog,
  setReloadAudit,
  setAlert,
  setActionId,
  setFetchGetEmailNotification,
  { values, universityId },
  t
) => {
  try {
    const data = {
      actionState: values.actionState,
      applicationDocumentUrl: values.applicationDocumentUrl,
      endDate: values.endDate,
      note: values.note,
      percentageOnDuty: values.percentageOnDuty,
      startDate: values.startDate,
      type: values.type,
      notificationRemarks: values.notificationRemarks,
      leavePercentageIncrease: values.leavePercentageIncrease,
      dataSource: values.dataSource,
    };
    const response = await axios({
      url: `/faculty/${universityId}/leaves`,
      method: "post",
      data,
    });
    setActionId(response.data.actionId);
    setReloadAudit(response.data.actionId);
    setOpenDialog(false);
    setFetchGetEmailNotification(true);
  } catch (error) {
    const errorMessage = "LeaveAccruedTaken.leaveActionForm.messages";
    if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      error.response.data.detail.properties[0].name === "leave.type"
    ) {
      setAlert(
        "error",
        t(`${errorMessage}.incorrectLeaveTypeMsg`),
        false,
        false
      );
    } else if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      error.response.data.detail.properties[0].name === "leave.Dates"
    ) {
      setAlert(
        "error",
        t(`${errorMessage}.outsideAppointmentMsg`),
        false,
        false
      );
    } else if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
      setAlert("error", t(`${errorMessage}.duplicateLeaveMsg`), false, false);
    } else {
      setAlert("error", error.message, false, false);
    }
  } finally {
    setShowSaveConfirmationMsg(false);
  }
};
// Service to fetch leave details
export const getLeaveDetails = async (
  setLeaveDetails,
  setHasCrossSchoolAppointment,
  setAlert,
  universityId,
  leaveId
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/leaves/${leaveId}`,
    });
    const leaveDetailsValue = {
      ...response.data,
      startDate: `${response.data.startDate}T00:00:00`,
      endDate: `${response.data.endDate}T00:00:00`,
    };
    setLeaveDetails(leaveDetailsValue);
    if (response.data) {
      checkCrossSchoolAppointment(
        setHasCrossSchoolAppointment,
        universityId,
        response.data.startDate,
        response.data.endDate,
        response.data.type
      );
    }
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};

export const fetchLeaveValidation = async (
  universityId,
  leave,
  codeToMessage,
  showDialog,
  setError,
  setWarning
) => {
  try {
    const response = await axios.post(
      `/faculty/${universityId}/leaves/validate`,
      leave
    );
    const data = response.data;
    if (Array.isArray(data.errors) && data.errors.length) {
      const errorMessage = validationCodesToErrorMessage(
        data.errors,
        codeToMessage
      );
      setError(errorMessage);
      setWarning(""); // clear warnings
      showDialog(false);
    } else if (Array.isArray(data.warnings) && data.warnings.length) {
      const warningMessage = validationCodesToErrorMessage(
        data.warnings,
        codeToMessage
      );
      setWarning(warningMessage);
      showDialog(true);
    } else {
      setWarning(""); // clear warnings
      showDialog(true);
    }
  } catch (error) {
    setError(error.message);
  }
};

const validationCodesToErrorMessage = (errorMessages, codeToMessage) => {
  return errorMessages.map(codeToMessage).join(", ");
};

// Service to fetch leave details
export const getLeaveInfoDetails = async (
  setLeaveInfoDetails,
  setAlert,
  universityId,
  leaveId
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/leaves/${leaveId}/info`,
    });
    setLeaveInfoDetails(response.data);
  } catch (error) {
    setAlert("error", error.message);
  }
};

// Service to update Leave Action
export const updateLeaveAction = async (
  setShowSaveConfirmationMsg,
  setOpenDialog,
  setShowInfoDetails,
  setReloadAudit,
  setReloadAccrual,
  setAlert,
  setActionId,
  setFetchGetEmailNotification,
  { values, universityId, actionId },
  t
) => {
  try {
    const data = {
      actionId: actionId,
      actionState: values.actionState,
      applicationDocumentUrl: values.applicationDocumentUrl,
      endDate: values.endDate,
      leaveNotes: values.note,
      percentageOnDuty: values.percentageOnDuty,
      startDate: values.startDate,
      type: values.type,
      notificationRemarks: values.notificationRemarks,
      leavePercentageIncrease: values.leavePercentageIncrease,
      dataSource: values.dataSource,
    };
    const response = await axios({
      url: `/faculty/${universityId}/leaves/${actionId}`,
      method: "put",
      data,
    });
    setActionId(actionId);
    setReloadAudit(response.data.leaveId);
    setReloadAccrual(response.data.leaveRecalculated);
    setOpenDialog(false);
    setFetchGetEmailNotification(true);
  } catch (error) {
    if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      error.response.data.detail.properties[0].name === "percentageToUse"
    ) {
      setAlert(
        "error",
        t("LeaveAccruedTaken.leaveActionForm.messages.percentageToUseErrorMsg"),
        false,
        false
      );
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setShowSaveConfirmationMsg(false);
    setShowInfoDetails(false);
  }
};

// Service to check cross school appointment
export const checkCrossSchoolAppointment = async (
  setHasCrossSchoolAppointment,
  universityId,
  startDate,
  endDate,
  type
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/appointments/hasCrossSchoolAppointment`,
      params: {
        leaveEndDate: endDate,
        leaveStartDate: startDate,
        leaveType: type,
      },
    });
    setHasCrossSchoolAppointment(response.data.hasCrossSchoolAppointments);
  } catch (error) {
    setHasCrossSchoolAppointment(false);
  }
};

// Service to fetch Average FTE for last 3 years
export const getAverageFTELast3Years = async (
  setAverageFTELast3Years,
  setAlert,
  universityId,
  startDate
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/averageFteThreeYears?startDate=${startDate}`,
    });
    setAverageFTELast3Years(response.data);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getLeaveEmailNotification = async (
  universityId,
  leaveId,
  setShowEmailNotificationDialog,
  setAlert,
  setEmailDetails,
  setReloadLeave,
  setFetchGetEmailNotification
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/leaves/${leaveId}/notification`,
    });
    setFetchGetEmailNotification(false);
    if (response.status === 200) {
      setEmailDetails(response.data);
      setShowEmailNotificationDialog(true);
      return;
    }
    setShowEmailNotificationDialog(false);
    setReloadLeave(true);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const sendLeaveEmailNotification = async (
  universityId,
  actionId,
  toValue,
  ccValue,
  emailDetails,
  setReloadLeave,
  setShowEmailNotificationDialog,
  setAlert
) => {
  try {
    const data = {
      body: emailDetails.body,
      subject: emailDetails.subject,
      to: toValue,
      cc: ccValue,
    };
    await axios({
      url: `/faculty/${universityId}/leaves/${actionId}/notification`,
      method: "post",
      data,
    });
    setReloadLeave(true);
    setShowEmailNotificationDialog(false);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const saveLeaveSectionNotes = async (
  universityId,
  leaveSectionNotes,
  setShowLeaveSectionNotesDialog,
  setReloadAppointments,
  setAlert
) => {
  try {
    const data = {
      note: leaveSectionNotes,
    };
    await axios({
      url: `/faculty/${universityId}/leave-note`,
      method: "patch",
      data,
    });
    setReloadAppointments(true);
    setShowLeaveSectionNotesDialog(false);
  } catch (error) {
    setAlert("error", error.message);
  }
};
