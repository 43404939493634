import { useTranslation } from "react-i18next";
import { Link as MuiLink, Grid as MuiGrid } from "@mui/material";
import {
  Clear as MuiClearIcon,
  Refresh as MuiRefreshIcon,
} from "@mui/icons-material";

export const ResetFilter = ({ resetFn, clearFn }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  return (
    <MuiGrid container justifyContent="flex-end" sx={{ paddingRight: "6px" }}>
      {/* Reset Filter Container, containing reset interface. */}
      <MuiGrid item xs={6}>
        <MuiClearIcon sx={{ verticalAlign: "middle" }} fontSize="small" />
        <MuiLink
          component="button"
          underline="none"
          color="textPrimary"
          onClick={() => clearFn()}
        >
          {t("FLA.filterPanel.clearFilterText")}
        </MuiLink>
      </MuiGrid>
      <MuiGrid item>
        <MuiRefreshIcon sx={{ verticalAlign: "middle" }} fontSize="small" />
        <MuiLink
          component="button"
          underline="none"
          color="textPrimary"
          /**
           * On click of Reset Filter
           *
           * 1. Calling up the function named resetFilter
           * to reset all of the state values and reloading
           * the data based on that.
           * 2. Definition of reset filter function will
           * vary with different components it belongs to.
           * */
          onClick={() => resetFn()}
        >
          {t("FLA.filterPanel.resetFilterText")}
        </MuiLink>
      </MuiGrid>
    </MuiGrid>
  );
};
