import { useState } from "react";
import { useTranslation } from "react-i18next";
/** Material UI Imports **/
import { Grid as MuiGrid, Typography as MuiTypography } from "@mui/material";
import {
  Info as MuiInfoIcon,
  EditOutlined as MuiEditOutlinedIcon,
} from "@mui/icons-material";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { AccrualAdjustment } from "./AccrualAdjustment/AccrualAdjustment";
import { FormattedNumber } from "app/shared/ui/FormattedNumber";
import { SegmentsColor } from "app/shared/constants";

//Function to create the subsection panels of Accrual
export const AccrualSubPanels = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const accrualLabels = "LeaveAccruedTaken.accrualLabels";
  const {
    sabbaticalLeaves,
    pdlLeaves,
    additionalLeaveDetails,
    sabFileName,
    pdlFileName,
    year,
  } = props.leavesData;
  const leavesTypeArray = [sabbaticalLeaves, pdlLeaves];

  const createTooltipValue = () => {
    return (
      <>
        <MuiGrid container>
          {additionalLeaveDetails &&
            t(`${accrualLabels}.tooltipTexts.tooltipDataText`, {
              filename:
                (pdlLeaves && pdlFileName) || (sabbaticalLeaves && sabFileName),
            })}
        </MuiGrid>
        <br />
        {additionalLeaveDetails &&
          additionalLeaveDetails.map((data, key) => (
            <MuiTypography variant="caption" key={key}>
              {data.comment &&
                data.comment.split("\n").map((value) => (
                  <div key={value}>
                    {value}
                    <br />
                  </div>
                ))}
              {data.note &&
                data.note.split("\n").map((value) => (
                  <div key={value}>
                    {value}
                    <br />
                  </div>
                ))}
              <br />
            </MuiTypography>
          ))}
      </>
    );
  };

  let columnData = [
    {
      id: "jan",
      value: t(`${accrualLabels}.jan`),
      width: "7%",
    },
    {
      id: "feb",
      value: t(`${accrualLabels}.feb`),
      width: "7%",
    },
    {
      id: "mar",
      value: t(`${accrualLabels}.mar`),
      width: "7%",
    },
    {
      id: "apr",
      value: t(`${accrualLabels}.apr`),
      width: "7%",
    },
    {
      id: "may",
      value: t(`${accrualLabels}.may`),
      width: "7%",
    },
    {
      id: "jun",
      value: t(`${accrualLabels}.jun`),
      width: "7%",
    },
    {
      id: "jul",
      value: t(`${accrualLabels}.jul`),
      width: "7%",
    },
    {
      id: "aug",
      value: t(`${accrualLabels}.aug`),
      width: "7%",
    },
    {
      id: "sep",
      value: t(`${accrualLabels}.sep`),
      width: "7%",
    },
    {
      id: "oct",
      value: t(`${accrualLabels}.oct`),
      width: "7%",
    },
    {
      id: "nov",
      value: t(`${accrualLabels}.nov`),
      width: "7%",
    },
    {
      id: "dec",
      value: t(`${accrualLabels}.dec`),
      tooltipHeader:
        additionalLeaveDetails &&
        t(`${accrualLabels}.tooltip.accrualRate.text`),
      tooltipIcon: additionalLeaveDetails && (
        <MuiInfoIcon color="primary" fontSize="small" viewBox="0 0 22 22" />
      ),
      tooltipValue: createTooltipValue(),
    },
  ];
  const rowsData = [];
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const columnsData = [];
  // State for Dialog
  const [openAccrualAdjustmentDialog, setOpenAccrualAdjustmentDialog] =
    useState(false);
  const [category, setCategory] = useState();
  leavesTypeArray.length &&
    leavesTypeArray
      .filter((prop, value) => Boolean(prop))
      .map((data, key, index) => {
        // The first column header needs to be dynamic as per leave type
        let columnHeader = [];
        columnHeader = [
          {
            id: "row_title",
            value:
              sabbaticalLeaves && pdlLeaves
                ? key > 0
                  ? t(`${accrualLabels}.pdl`)
                  : t(`${accrualLabels}.sabbatical`)
                : pdlLeaves
                ? t(`${accrualLabels}.pdl`)
                : t(`${accrualLabels}.sabbatical`),
            width: "14%",
            icon: props.editAccrual && (
              <MuiEditOutlinedIcon
                onClick={() => {
                  setOpenAccrualAdjustmentDialog(true);
                  setCategory(
                    sabbaticalLeaves && pdlLeaves
                      ? key > 0
                        ? t(`${accrualLabels}.pdl`)
                        : t("globals.labels.categorySAB")
                      : pdlLeaves
                      ? t(`${accrualLabels}.pdl`)
                      : t("globals.labels.categorySAB")
                  );
                }}
                fontSize="small"
                viewBox="0 0 25 25"
                sx={{ cursor: "pointer", marginLeft: "5px" }}
              />
            ),
          },
          ...columnData,
        ];
        columnsData.push(columnHeader);

        let leaveData = {
          beginningBalance: {},
          accrued: {},
          taken: {},
          endBalance: {},
        };
        data.map((data, key) => {
          leaveData.accrued[months[key]] = {
            value: data.accrued && FormattedNumber(data.accrued),
          };
          leaveData.beginningBalance[months[key]] = {
            value:
              data.beginningBalance && FormattedNumber(data.beginningBalance),
          };
          leaveData.endBalance[months[key]] = {
            value: data.endBalance && FormattedNumber(data.endBalance),
          };
          leaveData.taken[months[key]] = {
            value: (data.taken && FormattedNumber(data.taken)) || 0,
          };
          return leaveData;
        });

        const leavesData =
          leaveData &&
          Object.entries(leaveData).map(([key, value]) => {
            return {
              ...value,
              row_title: { value: t(`${accrualLabels}.${key}`) },
            };
          });

        rowsData.push(leavesData);
        return rowsData;
      });
  return (
    <MuiGrid container spacing={2} direction="column">
      {rowsData.length > 0 &&
        rowsData.map((data, key) => (
          <MuiGrid item container key={key} direction="row">
            <MuiGrid
              item
              sx={{
                background:
                  columnsData[key][key].value ===
                  t(`${accrualLabels}.sabbatical`)
                    ? SegmentsColor["SAB_LIGHT"]
                    : SegmentsColor["PDL_LIGHT"],
                width: "6px",
                margin: "10px",
              }}
            ></MuiGrid>
            <MuiGrid item xs={11}>
              <AccordionSummaryTable
                columnData={columnsData[key]}
                rowData={data}
              />
            </MuiGrid>
          </MuiGrid>
        ))}
      <AccrualAdjustment
        open={openAccrualAdjustmentDialog}
        handleCancel={() => {
          setOpenAccrualAdjustmentDialog(false);
        }}
        category={category}
        year={year}
        noteTooltip={additionalLeaveDetails && createTooltipValue()}
        setReloadAccrual={props.setReloadAccrual}
        setReloadAudit={props.setReloadAudit}
      />
    </MuiGrid>
  );
};
