import { Container as MuiContainer, Box as MuiBox } from "@mui/material";
import { AppRoutes } from "app/AppRoutes";
import PropTypes from "prop-types";

const containerStyles = {
  overflowX: "auto",
};

export const MainContainer = ({ idAttr }) => {
  return (
    <MuiContainer maxWidth="false" sx={containerStyles}>
      <MuiBox
        id={idAttr}
        display="inline-block"
        sx={{
          "@media (min-width: 1480px)": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <AppRoutes />
      </MuiBox>
    </MuiContainer>
  );
};

MainContainer.propTypes = {
  idAttr: PropTypes.string.isRequired,
};
