import { useRef } from "react";
import { Paper as MuiPaper } from "@mui/material";
import Draggable from "react-draggable";

export const DraggablePaper = (props) => {
  const nodeRef = useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <MuiPaper ref={nodeRef} {...props} />
    </Draggable>
  );
};
