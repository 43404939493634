import { format, addMinutes } from "date-fns";

/* Function to format a date string in yyyy-MM-dd format */
export const convertDateStrToFLADateStr = (dateStr) => {
  return dateStr
    ? format(
        addMinutes(new Date(dateStr), new Date(dateStr).getTimezoneOffset()),
        "yyyy-MM-dd"
      )
    : "";
};

/* Function to format a date string in yyyy-MM-dd HH:mm:ss format */
export const convertDateStrToFLADateTimeStr = (dateStr) => {
  return dateStr
    ? format(
        addMinutes(new Date(dateStr), new Date(dateStr).getTimezoneOffset()),
        "yyyy-MM-dd HH:mm:ss"
      )
    : "";
};

export const convertUTCDateStrToFLADateTimeStr = (dateStr) => {
  return dateStr
    ? format(new Date(dateStr.toLocaleString()), "yyyy-MM-dd HH:mm:ss")
    : "";
};

/* Function to format the Decimal Values,
 * if the length of Decimal digits is greater than 3,
 * then round-off the value to 3 decimal places (keeping the trailing zeros),
 * otherwise return the same value
 */
export const formatDecimalValues = (value) =>
  (value % 1 !== 0 &&
    value.toString().split(".")[1].length > 3 &&
    value.toFixed(3)) ||
  value;

/* Function to format the Decimal value to convert it into percentage value */
export const numberToPercentage = (num) => {
  return +(Math.round(num * 100 + "e+3") + "e-3") + "%";
};
