import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox as MuiCheckbox,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
} from "@mui/material";

export const FacultyLeaveTableHead = ({
  order,
  orderBy,
  onRequestSort,
  handleCheckAll,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleCheckAll(event.target.checked);
  };
  return (
    <MuiTableHead
      sx={{
        // keeps the Table Header Label text white
        "& .MuiTableSortLabel-root": {
          color: "white !important",
        },
        "& .MuiTableSortLabel-root:hover": {
          color: "white",
        },
        "&.Mui-active": {
          color: "white",
        },
        "& .MuiTableSortLabel-icon": {
          color: "white !important",
          alignSelf: "center",
        },
      }}
    >
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="checkbox"
          padding="checkbox"
          sx={{ display: "none" }}
        >
          <MuiCheckbox
            disableRipple
            checked={checked}
            onChange={handleChange}
            size="medium"
            color="primary"
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="name"
          sortDirection={orderBy === "name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "name"}
            onClick={createSortHandler("name")}
            direction={orderBy === "name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.name")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="title"
          sortDirection={orderBy === "title" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "title"}
            onClick={createSortHandler("title")}
            direction={orderBy === "title" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.rank")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="facultyLine"
          sortDirection={orderBy === "facultyLine" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "facultyLine"}
            onClick={createSortHandler("facultyLine")}
            direction={orderBy === "facultyLine" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.line")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="department.name"
          sortDirection={orderBy === "department.name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "department.name"}
            onClick={createSortHandler("department.name")}
            direction={orderBy === "department.name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.department")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="startDate"
          sortDirection={orderBy === "startDate" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "startDate"}
            onClick={createSortHandler("startDate")}
            direction={orderBy === "startDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptStart")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="endDate"
          sortDirection={orderBy === "endDate" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "endDate"}
            onClick={createSortHandler("endDate")}
            direction={orderBy === "endDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptEnd")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="activeFlag"
          sortDirection={orderBy === "activeFlag" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "activeFlag"}
            onClick={createSortHandler("activeFlag")}
            direction={orderBy === "activeFlag" ? order : "asc"}
          >
            {t("FLA.filterPanel.options.activeInactive.label")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="actions">
          {t("FLA.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
