import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  TextField as MuiTextField,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Alert, useAlerts } from "common";
import { saveLeaveSectionNotes } from "app/services/leaveActionService";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";

export const LeaveSectionNotes = (props) => {
  const {
    open,
    onClose,
    editLeaveSectionNotes,
    universityId,
    setShowLeaveSectionNotesDialog,
    setReloadAppointments,
    existingLeaveNote,
  } = props;
  const [leaveSectionNotes, setLeaveSectionNotes] = useState(
    existingLeaveNote || ""
  );
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    if (open) {
      clearAlert();
      setLeaveSectionNotes(existingLeaveNote || "");
    }
  }, [open, clearAlert, existingLeaveNote]);

  const handleSubmit = () => {
    saveLeaveSectionNotes(
      universityId,
      leaveSectionNotes,
      setShowLeaveSectionNotesDialog,
      setReloadAppointments,
      setAlert
    );
  };

  return (
    <MuiDialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") onClose();
      }}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
      hideBackdrop
      disableScrollLock
      onClick={(event) => {
        event.stopPropagation();
      }}
      fullWidth
    >
      <MuiDialogTitle id="draggable-dialog-title" sx={{ cursor: "move" }}>
        {t("LeaveAccruedTaken.leaveHistoryLabels.leaveSectionNotesTitle")}
      </MuiDialogTitle>
      {alert.exists && <Alert />}
      <MuiDialogContent sx={{ padding: "8px 24px" }}>
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="leaveSectionNotes"
              label={t(
                "LeaveAccruedTaken.leaveHistoryLabels.leaveSectionNotesTitle"
              )}
              multiline
              fullWidth
              variant={(editLeaveSectionNotes && "outlined") || "standard"}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                setLeaveSectionNotes(event.target.value);
              }}
              value={leaveSectionNotes}
              disabled={!editLeaveSectionNotes}
              sx={{ marginTop: "4px " }}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiGrid container item xs={6} justifyContent="space-evenly">
          <MuiGrid item xs={5}>
            <MuiButton
              variant="outlined"
              color="primary"
              onClick={onClose}
              fullWidth
            >
              {t("globals.form.actionButtons.cancel")}
            </MuiButton>
          </MuiGrid>

          <MuiGrid item xs={5}>
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!editLeaveSectionNotes}
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("globals.form.actionButtons.save")}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiDialogActions>
    </MuiDialog>
  );
};
