import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  LeaveTypes,
  Status,
  AUTOCOMPLETE_MINIMUM_CHARS,
} from "app/shared/constants";
import {
  getInitialLineDepartmentOptions,
  getDivisionOptionsFromDepartments,
} from "app/services/facultyLeaveFilterService";
import { useGetPersonOptions } from "app/services/autocompleteService";
import { useFacultyLeaveContext } from "app/services/facultyLeaveContext";
import { ResetFilter } from "app/shared/ui/ResetFilter";

export const FacultyLeaveFilter = () => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for all translation keys
  const facultyLeaveFilterLabels = "FLA.filterPanel.options";
  const { loadAutocomplete } = useGetPersonOptions();
  // Call to Context
  const {
    facultyLeaveFiltersPreference,
    setFacultyLeaveFiltersPreference,
    setPagination,
    pagination,
  } = useFacultyLeaveContext();

  /** State variable for university id value of person filter **/
  const [universityId, setUniversityId] = useState(
    facultyLeaveFiltersPreference.universityId
  );

  /**  State for person,department,line,status and category dropdown Input values **/
  const [filterPersonInputValue, setFilterPersonInputValue] = useState("");
  const [departmentFilterInputValue, setDepartmentFilterInputValue] = useState(
    []
  );
  const [divisionFilterInputValue, setDivisionFilterInputValue] = useState([]);
  const [filterLineInputValue, setFilterLineInputValue] = useState([]);
  const [statusInputValue, setStatusInputValue] = useState([Status["Active"]]);
  const [apptTypeInputValue, setApptTypeInputValue] = useState([
    LeaveTypes["SAB"],
    LeaveTypes["PDL"],
  ]);

  /** State for person,department,line dropdown options **/
  const [personOptions, setPersonOptions] = useState([]);
  const [lineOptions, setLineOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  // State for Filter errors
  const [personFilterError, setPersonFilterError] = useState({
    exist: false,
    message: null,
  });

  const [prefetchFiltersError, setPrefetchFiltersError] = useState({
    exist: false,
    message: null,
  });
  // Function to reset the Filter value
  const resetFn = () => {
    setFilterPersonInputValue("");
    setPersonOptions([]);
    setDepartmentFilterInputValue([]);
    setDivisionFilterInputValue([]);
    setFilterLineInputValue([]);
    setStatusInputValue([Status["Active"]]);
    setApptTypeInputValue([LeaveTypes["SAB"], LeaveTypes["PDL"]]);
    setFacultyLeaveFiltersPreference({
      universityId: "",
      department: "",
      division: "",
      line: "",
      status: "Active",
      apptType: "SAB,PDL",
    });
    setPrefetchFiltersError({
      exist: false,
      message: null,
    });
    setPersonFilterError({
      exist: false,
      message: null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // Function to clear the Filter value
  const clearFn = () => {
    setFilterPersonInputValue("");
    setPersonOptions([]);
    setDepartmentFilterInputValue([]);
    setDivisionFilterInputValue([]);
    setFilterLineInputValue([]);
    setStatusInputValue([]);
    setApptTypeInputValue([]);
    setFacultyLeaveFiltersPreference({
      universityId: "",
      department: "",
      division: "",
      line: "",
      status: "",
      apptType: null,
    });
    setPrefetchFiltersError({
      exist: false,
      message: null,
    });
    setPersonFilterError({
      exist: false,
      message: null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // Fetch person filter options, when URL params includes its value
  const [loadPersonOptions, setLoadPersonOptions] = useState(false);

  if (facultyLeaveFiltersPreference.universityId && !loadPersonOptions) {
    loadAutocomplete(
      setPersonOptions,
      setPersonFilterError,
      facultyLeaveFiltersPreference.universityId
    );
    setLoadPersonOptions(true);
  }

  /** Call to service for prefetch line and department filters options */
  useEffect(() => {
    getInitialLineDepartmentOptions(
      setLineOptions,
      setDepartmentOptions,
      setPrefetchFiltersError
    );
  }, []);

  /*Whenever URL parameters already contain the Query String for
   *"Line of Appointment","Person" and "Department" then
   *this methods will return those objects for selected options
   *to set the corresponding options in the field.
   */
  const personInputValueByURL = personOptions
    .filter(({ value }) => value["universityId"] === universityId)
    .reduce((acc, val) => (acc += val.displayText), "");

  // TextField onChange Handler for Person Autocomplete
  const handlePersonAutocompleteChange = (personInput) => {
    setFilterPersonInputValue(personInput);
    setUniversityId(personInput);

    if (personInput && personInput.length >= AUTOCOMPLETE_MINIMUM_CHARS) {
      loadAutocomplete(setPersonOptions, setPersonFilterError, personInput);
    }
    if (!personInput) {
      setFacultyLeaveFiltersPreference({
        ...facultyLeaveFiltersPreference,
        universityId: "",
      });
    }
  };

  // onChange Handler for Department Autocomplete
  const handleDepartmentAutocompleteChange = (departmentValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      department: departmentValue.map((option) => option.code).toString(),
    });

    setPagination({
      ...pagination,
      page: 0,
    });
    setDepartmentFilterInputValue(departmentValue.map((option) => option));
    /** Call to service to fetch division filters options */
    getDivisionOptionsFromDepartments(
      departmentValue.map((option) => option.code).toString(),
      setDivisionOptions,
      setDivisionFilterInputValue,
      setPrefetchFiltersError
    );
    // Clear Data When No Department Value Selected.
    if (
      !divisionOptions.length &&
      facultyLeaveFiltersPreference.division.length
    ) {
      setFacultyLeaveFiltersPreference({
        ...facultyLeaveFiltersPreference,
        division: "",
      });
    }
  };

  // onChange Handler for Division
  const handleDivisionAutocompleteChange = (divisionValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      division: divisionValue.map((option) => option.code).toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
    setDivisionFilterInputValue(divisionValue.map((option) => option));
  };
  // onChange Handler for Line Autocomplete
  const handleLineAutocompleteChange = (lineValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      line: lineValue.map((option) => option.value.abbreviation).toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
    setFilterLineInputValue(lineValue.map((option) => option));
  };

  // onChange Handler for Status/Active/Inactive Autocomplete
  const handleStatusAutocompleteChange = (statusValue) => {
    setStatusInputValue(statusValue);
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      status: Object.keys(Status)
        .filter((optionKey) => statusValue.includes(Status[optionKey]))
        .toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // onChange Handler for Appt. Category Autocomplete
  const handleApptTypeAutocompleteChange = (apptTypeValue) => {
    setApptTypeInputValue(apptTypeValue);
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      apptType: apptTypeValue.length
        ? Object.keys(LeaveTypes)
            .filter((optionKey) =>
              apptTypeValue.includes(LeaveTypes[optionKey])
            )
            .toString()
        : null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  return (
    <>
      <MuiFormControl variant="outlined" size="small">
        <MuiAutocomplete
          size="small"
          id="personFilter"
          options={personOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          inputValue={filterPersonInputValue || personInputValueByURL}
          clearOnEscape
          onOpen={(event) => {
            if (personOptions.length) {
              setPersonOptions([]);
              !filterPersonInputValue &&
                setFacultyLeaveFiltersPreference({
                  ...facultyLeaveFiltersPreference,
                  universityId: "",
                });
            }
          }}
          onChange={(e, value) => {
            setFacultyLeaveFiltersPreference({
              ...facultyLeaveFiltersPreference,
              universityId: (value && value.value.universityId) || "",
            });
            setFilterPersonInputValue((value && value.displayText) || "");
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          filterSelectedOptions
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.person.label`)}
              variant="outlined"
              onChange={(e) => handlePersonAutocompleteChange(e.target.value)}
              error={personFilterError.exist}
              helperText={personFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="departmentFilter"
          multiple
          value={
            (departmentFilterInputValue.length && departmentFilterInputValue) ||
            departmentInputValueByURL(
              departmentOptions,
              facultyLeaveFiltersPreference
            )
          }
          options={departmentOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          clearOnEscape
          onChange={(e, value) => handleDepartmentAutocompleteChange(value)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.department.label`)}
              variant="outlined"
              error={prefetchFiltersError.exist}
              helperText={prefetchFiltersError.message}
            />
          )}
        />
      </MuiFormControl>
      {divisionOptions && divisionOptions.length ? (
        <MuiFormControl variant="outlined">
          <MuiAutocomplete
            size="small"
            id="divisionFilter"
            multiple
            value={
              (divisionFilterInputValue.length && divisionFilterInputValue) ||
              divisionInputValueByURL(
                divisionOptions,
                facultyLeaveFiltersPreference
              )
            }
            options={divisionOptions || []}
            getOptionLabel={(option) => option && option.displayText}
            clearOnEscape
            onChange={(e, value) => handleDivisionAutocompleteChange(value)}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                label={t(`${facultyLeaveFilterLabels}.division.label`)}
                variant="outlined"
                error={prefetchFiltersError.exist}
                helperText={prefetchFiltersError.message}
              />
            )}
          />
        </MuiFormControl>
      ) : null}
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="lineFilter"
          multiple
          value={
            (filterLineInputValue.length && filterLineInputValue) ||
            lineInputValueByURL(lineOptions, facultyLeaveFiltersPreference)
          }
          options={lineOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          clearOnEscape
          onChange={(e, value) => handleLineAutocompleteChange(value)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.line.label`)}
              variant="outlined"
              error={prefetchFiltersError.exist}
              helperText={prefetchFiltersError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="activeFilter"
          value={statusInputValue || []}
          multiple
          options={Object.values(Status)}
          getOptionLabel={(option) => t(option)}
          clearOnEscape
          onChange={(e, value) => handleStatusAutocompleteChange(value)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.activeInactive.label`)}
              variant="outlined"
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="categoryFilter"
          multiple
          value={apptTypeInputValue}
          options={Object.values(LeaveTypes)}
          getOptionLabel={(option) =>
            option === "LeaveAccruedTaken.appointmentSegmentsLabels.PDL"
              ? t("LeaveAccruedTaken.accrualLabels.pdl")
              : t(option)
          }
          clearOnEscape
          onChange={(e, value) => handleApptTypeAutocompleteChange(value)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.category.label`)}
              variant="outlined"
            />
          )}
        />
      </MuiFormControl>
      <ResetFilter resetFn={resetFn} clearFn={clearFn} />
    </>
  );
};

/* Get Input Value By URL for Line of Appointment */
const lineInputValueByURL = (lineOptions, facultyLeaveFiltersPreference) =>
  lineOptions
    .filter(({ value }) =>
      facultyLeaveFiltersPreference.line.includes(value["abbreviation"])
    )
    .map((value) => value);

/* Get Input Value By URL for Department Filter */
const departmentInputValueByURL = (
  departmentOptions,
  facultyLeaveFiltersPreference
) =>
  departmentOptions
    .filter((value) =>
      facultyLeaveFiltersPreference.department.includes(value["code"])
    )
    .map((value) => value);

/* Get Input Value By URL for Division Filter */
const divisionInputValueByURL = (
  divisionOptions,
  facultyLeaveFiltersPreference
) =>
  divisionOptions
    .filter((value) =>
      facultyLeaveFiltersPreference.division.includes(value["code"])
    )
    .map((value) => value);
