import { useState } from "react";
/**  Material UI Imports **/
import {
  Grid as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  TextField as MuiTextField,
  RadioGroup as MuiRadioGroup,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  Box as MuiBox,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  Help as MuiHelpIcon,
  Info as MuiInfoIcon,
  Lens as MuiLensIcon,
} from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
/** Custom Component **/
import {
  formatDecimalValues,
  convertDateStrToFLADateStr,
} from "app/shared/utils";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";
/** ENUM import **/
import { AdjustmentType, SegmentsColor } from "app/shared/constants";
/** Service Import */
import {
  adjustAccruals,
  getAccrualBegBalance,
} from "app/services/accrualAdjustmentService";
/** Context and Component to show error on UI **/
import { Alert, useAlerts } from "common";

// Initial Form Values
const accrualAdjustFormValues = {
  effectiveDate: "",
  existingBegBalance: 0.0,
  adjustValue: "",
  adjustType: AdjustmentType.ADD,
  notes: "",
};
// **** Form Validation Schema ****
const AccrualAdjustmentFormSchema = Yup.object().shape({
  effectiveDate: Yup.string().required("Required"),
  adjustValue: Yup.number().required("Required"),
});

export const AccrualAdjustment = (props) => {
  const { t } = useTranslation();
  const accrualAdjustmentLabels =
    "LeaveAccruedTaken.accrualLabels.accrualAdjustmentDialog";
  const {
    open,
    handleCancel,
    category,
    year,
    noteTooltip,
    setReloadAccrual,
    setReloadAudit,
  } = props;
  //Get universityId from url params
  const { universityId } = useParams();
  const { alert, setAlert, clearAlert } = useAlerts();
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] = useState(false);
  const [accrualBegBalance, setAccrualBegBalance] = useState("");

  /** Function to call on form submit */
  const handleFormSubmit = (values) => {
    adjustAccruals(
      setReloadAccrual,
      setReloadAudit,
      handleCancel,
      setAlert,
      setShowSaveConfirmationMsg,
      {
        universityId,
        values,
        category,
      },
      t
    );
  };
  /** Helper function to populate Effective Date dropdown options.
      It returns the first date of 12 months in the selected Accrual Adjustment year. */
  const populateEffectiveDateOptions = (accrualAdjustmentYear) => {
    let firstDate = new Date(accrualAdjustmentYear, 0);
    const dateOptions = Array.apply(null, { length: 12 }).map((_, month) =>
      convertDateStrToFLADateStr(firstDate.setMonth(month))
    );
    return dateOptions;
  };

  const dateOptions = populateEffectiveDateOptions(year);

  const calculateResultingEndBalance = (values) => {
    if (!accrualBegBalance || !values.adjustValue || !values.adjustType) {
      //  we don't have all values yet -> keep field empty
      return "";
    }

    let adjustValue = valueAndTypeToSignedValue(
      values.adjustValue,
      values.adjustType
    );
    return formatDecimalValues(accrualBegBalance + adjustValue);
  };

  const valueAndTypeToSignedValue = (value, type) => {
    let v = parseFloat(value);
    let result = 0.0;
    if (type === AdjustmentType.ADD) {
      result = v;
    } else if (type === AdjustmentType.SUBTRACT) {
      result = -v;
    }
    return result;
  };

  /** Helper function to populate Beg. Balance depending on
      selected Effective Date and Leave Type */
  const handleEffectiveDateChange = (
    effectiveDate,
    category,
    setFieldValue
  ) => {
    if (effectiveDate) {
      getAccrualBegBalance(setAlert, {
        universityId,
        effectiveDate,
        category,
      }).then((result) => {
        setAccrualBegBalance(result.begBalance);
        if (result.accrualAdjusted) {
          setFieldValue("adjustValue", result.adjustValue, false);
          setFieldValue("adjustType", result.adjustType, false);
        } else {
          setFieldValue("adjustValue", "", false);
        }
      });
    } else {
      setAccrualBegBalance("");
      setFieldValue("adjustValue", "", false);
    }
  };

  return (
    <MuiDialog
      open={open}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
      }}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "510px",
          },
        },
      }}
      hideBackdrop
      disableScrollLock
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          event.stopPropagation();
          handleCancel();
        }
      }}
      TransitionProps={{
        onEntered: () => {
          clearAlert();
          setAccrualBegBalance("");
        },
      }}
    >
      <MuiDialogTitle id="draggable-dialog-title" sx={{ cursor: "move" }}>
        <MuiGrid
          container
          justifyContent="space-between"
          direction="row"
          wrap="nowrap"
        >
          <MuiGrid container gap={1} item>
            <MuiGrid item>
              <MuiLensIcon
                fontSize="small"
                sx={{
                  color: SegmentsColor[`${category}_LIGHT`],
                  verticalAlign: "middle",
                }}
              />
            </MuiGrid>
            {t(`${accrualAdjustmentLabels}.title`, {
              category: category,
            })}
            <MuiGrid item>
              <MuiTypography variant="body1" fontStyle="italic">
                {t(`${accrualAdjustmentLabels}.impNote`)}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item>
            <MuiTooltip
              title={
                <>
                  <MuiTypography variant="body2">
                    {t(`${accrualAdjustmentLabels}.helpIconTooltipInitialText`)}
                  </MuiTypography>
                  <br />
                  <MuiTypography variant="body2">
                    {t(`${accrualAdjustmentLabels}.helpIconTooltipText`)}
                  </MuiTypography>
                </>
              }
              placement="right-start"
            >
              <MuiHelpIcon
                fontSize="small"
                color="primary"
                sx={{ cursor: "default" }}
              />
            </MuiTooltip>
          </MuiGrid>
        </MuiGrid>
        {alert.exists && <Alert />}
      </MuiDialogTitle>
      <MuiDialogContent sx={{ overflowY: "unset" }}>
        <Formik
          initialValues={accrualAdjustFormValues}
          enableReinitialize
          validationSchema={AccrualAdjustmentFormSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) =>
            handleFormSubmit(values)
          }
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleBlur,
              dirty,
              isValid,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form>
                <MuiGrid container>
                  <MuiGrid container item spacing={3} direction="column">
                    <MuiGrid item>
                      <MuiAutocomplete
                        id="effectiveDate"
                        options={dateOptions || []}
                        clearOnEscape
                        value={values.effectiveDate}
                        onChange={(e, value) => {
                          props.setFieldValue("effectiveDate", value);
                          handleEffectiveDateChange(
                            value,
                            category,
                            setFieldValue
                          );
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("globals.labels.effectiveDate")}*`}
                            variant="outlined"
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid container item justifyContent="space-between">
                      <MuiGrid
                        container
                        item
                        justifyContent="space-between"
                        xs={6}
                      >
                        <MuiGrid item>
                          <MuiTextField
                            id="existingBegBalance"
                            label={t(
                              `${accrualAdjustmentLabels}.existingBegBalance`
                            )}
                            size="small"
                            value={formatDecimalValues(accrualBegBalance)}
                            variant="standard"
                            autoComplete="off"
                            sx={{
                              width: "190px",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              "& .MuiInput-underline.Mui-disabled:before": {
                                borderBottomStyle: "solid",
                              },
                            }}
                            disabled
                          />
                        </MuiGrid>
                        <MuiGrid item>
                          <MuiTextField
                            id="resultingBegBalance"
                            label={t(
                              `${accrualAdjustmentLabels}.resultingBegBalance`
                            )}
                            size="small"
                            value={calculateResultingEndBalance(values)}
                            variant="standard"
                            autoComplete="off"
                            sx={{
                              width: "190px",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              "& .MuiInput-underline.Mui-disabled:before": {
                                borderBottomStyle: "solid",
                              },
                            }}
                            disabled
                          />
                        </MuiGrid>
                      </MuiGrid>
                      <MuiGrid
                        container
                        item
                        justifyContent="space-between"
                        xs={6}
                        alignContent="center"
                        alignItems="center"
                      >
                        <MuiGrid container item xs={2}>
                          <MuiGrid container item>
                            <MuiRadioGroup
                              aria-label="adjustmentType"
                              name="adjustType"
                              value={values.adjustType}
                              onChange={handleChange}
                            >
                              <MuiFormControlLabel
                                value={AdjustmentType.ADD}
                                control={<MuiRadio color="primary" />}
                                label={t(
                                  `${accrualAdjustmentLabels}.adjustTypeAdd`
                                )}
                              />
                              <MuiFormControlLabel
                                value={AdjustmentType.SUBTRACT}
                                control={<MuiRadio color="primary" />}
                                label={t(
                                  `${accrualAdjustmentLabels}.adjustTypeSubtract`
                                )}
                              />
                            </MuiRadioGroup>
                          </MuiGrid>
                        </MuiGrid>
                        <MuiGrid item xs={4}>
                          <NumericFormat
                            id="adjustValue"
                            label={`${t(
                              `${accrualAdjustmentLabels}.adjustValue`
                            )}*`}
                            value={values.adjustValue}
                            customInput={MuiTextField}
                            variant="outlined"
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isAllowed={(values) => {
                              const { value } = values;
                              return value >= 0.0 && value <= 999.0;
                            }}
                            decimalScale={3}
                            allowNegative
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item container>
                      <MuiGrid item xs={noteTooltip ? 11 : 12}>
                        <MuiTextField
                          id="notes"
                          label={t(
                            `${accrualAdjustmentLabels}.accrualAdjustmentNotes`
                          )}
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          multiline
                        />
                      </MuiGrid>
                      <MuiGrid item alignContent="center">
                        {noteTooltip && (
                          <MuiBox m={1}>
                            <MuiTooltip title={noteTooltip}>
                              <MuiInfoIcon
                                color="primary"
                                fontSize="small"
                                viewBox="0 0 22 22"
                              />
                            </MuiTooltip>
                          </MuiBox>
                        )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiDialogActions
                        sx={{ paddingBottom: "21px", paddingRight: "0px" }}
                      >
                        <MuiGrid
                          item
                          container
                          xs={6}
                          spacing={2}
                          justifyContent="flex-end"
                          wrap="nowrap"
                        >
                          <MuiGrid container item xs={6}>
                            <MuiButton
                              onClick={handleCancel}
                              variant="outlined"
                              fullWidth
                              color="primary"
                            >
                              {t("globals.form.actionButtons.cancel")}
                            </MuiButton>
                          </MuiGrid>
                          <MuiGrid container item xs={6}>
                            <MuiButton
                              variant="contained"
                              color="primary"
                              disabled={!dirty || !isValid}
                              fullWidth
                              onClick={() => setShowSaveConfirmationMsg(true)}
                            >
                              {t("globals.form.actionButtons.save")}
                            </MuiButton>
                            <ConfirmationDialog
                              open={showSaveConfirmationMsg}
                              message={t(
                                `${accrualAdjustmentLabels}.saveConfirmationMsg`
                              )}
                              handleCancel={() => {
                                setShowSaveConfirmationMsg(false);
                              }}
                              handleOk={handleSubmit}
                              okLabel={t("globals.form.actionButtons.yes")}
                              cancelLabel={t("globals.form.actionButtons.no")}
                            />
                          </MuiGrid>
                        </MuiGrid>
                      </MuiDialogActions>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </form>
            );
          }}
        </Formik>
      </MuiDialogContent>
    </MuiDialog>
  );
};
