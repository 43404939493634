import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Accordion as MuiAccordion,
  Typography as MuiTypography,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { ExpandMore as MuiExpandMoreIcon } from "@mui/icons-material";
import { Theme } from "common";

export const LeaveAccruedTakenAccordion = ({
  title = "",
  expandAll,
  leaveNotes = "",
  defaultExpanded = false,
  isSubExpansionPanel = false,
  hasSubSectionsExpansion = false,
  isButtonEnable = false,
  isSubOrdinateBtn = false,
  subOrdinateBtnText = "",
  handleChangeSubOrdinateBtn,
  contentButton = null,
  summaryDetails = null,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = () => (event, isExpanded) => {
    setExpanded(isExpanded);
  };
  useEffect(() => {
    if (expandAll !== undefined) {
      setExpanded(expandAll);
    }
  }, [expandAll]);

  // Custom styles for Accordion and AccordionSummary
  const accordionStyle = {
    width: "100%",
    marginTop: !isSubExpansionPanel && "16px",
    "& .Mui-expanded": {
      marginBottom: !isSubExpansionPanel && "0px",
    },
    "&:before": {
      background: "none",
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "50px",
    },
    ".MuiAccordionSummary-root.Mui-focusVisible": {
      background: Theme.palette.grey[700],
    },
  };
  const accordionSummaryStyle = {
    background: isSubExpansionPanel ? "none" : Theme.palette.grey[700],
    minHeight: "50px",
    height: "50px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    ".MuiTypography-body1": {
      fontWeight: isSubExpansionPanel ? "600" : "default",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      order: -1,
      marginRight: "12px",
      color: isSubExpansionPanel
        ? Theme.palette.common.black
        : Theme.palette.common.white,
    },
    ".MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
      color: isSubExpansionPanel
        ? Theme.palette.common.black
        : Theme.palette.common.white,
    },
    ".MuiAccordionSummary-contentGutters": {
      padding: "0px",
      margin: "0px",
    },
  };

  const getAccordionDetailsPadding = () => {
    if (hasSubSectionsExpansion) {
      return "8px 4px";
    } else if (isSubExpansionPanel) {
      return "8px 16px 0px";
    } else {
      return "default";
    }
  };

  return (
    <MuiAccordion
      key={title}
      elevation={isSubExpansionPanel ? 0 : 1}
      sx={accordionStyle}
      expanded={expanded}
      onChange={handleChange()}
    >
      <MuiAccordionSummary
        expandIcon={<MuiExpandMoreIcon />}
        sx={accordionSummaryStyle}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <MuiTypography variant={(isSubExpansionPanel && "body1") || "h2"}>
          {title}
        </MuiTypography>
        {contentButton}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={{
          flexDirection: "column",
          padding: getAccordionDetailsPadding(),
        }}
      >
        {isSubOrdinateBtn && (
          <MuiGrid
            container
            justifyContent={"flex-start"}
            sx={{ marginTop: "8px", marginLeft: "8px", marginBottom: "8px" }}
          >
            <MuiGrid item xs>
              <MuiButton
                variant="outlined"
                color="primary"
                disabled={!isButtonEnable}
                onClick={handleChangeSubOrdinateBtn}
              >
                {subOrdinateBtnText}
              </MuiButton>
            </MuiGrid>
            <MuiGrid item xs={10}>
              <MuiTooltip title={leaveNotes ?? ""} placement="bottom-start">
                <MuiTypography
                  noWrap
                  component="span"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    width: "65%",
                    fontStyle: "italic",
                  }}
                >
                  {leaveNotes ?? ""}
                </MuiTypography>
              </MuiTooltip>
            </MuiGrid>
          </MuiGrid>
        )}
        {summaryDetails}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
