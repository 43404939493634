import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
/**  Material UI Imports **/
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Dialog as MuiDialog,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TextField as MuiTextField,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import { Lens as MuiLensIcon, Help as MuiHelpIcon } from "@mui/icons-material";
/** Custom Component **/
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
/** ENUM import **/
import { SegmentsColor } from "app/shared/constants";
/** Service Import */
import {
  getIndividualMilestoneDetailsById,
  updateIndividualMilestone,
} from "app/services/individualMilestoneService";
/** Context and component to show error */
import { Alert, Theme, useAlerts } from "common";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";

export const ViewEditIndividualMilestone = (props) => {
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlerts();
  const editViewIndividualMilestoneLabels =
    "LeaveAccruedTaken.individualMilestoneForm";
  const {
    open,
    handleCancel,
    universityId,
    milestoneId,
    actionType,
    setReloadAudit,
  } = props;
  const [individualMilestoneForm, setIndividualMilestoneForm] = useState({
    category: "",
    date: "",
    description: "",
    dailyAccrual: "",
    maxAccrual: "",
    note: "",
  });
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] = useState(false);
  /* Function to format the Decimal Values,
   * if the length of Decimal digits is greater than 3,
   * then round-off the value to 3 decimal places (keeping the trailing zeros),
   * otherwise return the same value
   */
  const formatDecimalValues = (value) =>
    value && value % 1 !== 0 && value.toString().split(".")[1].length > 3
      ? value.toFixed(3)
      : value;
  useEffect(() => {
    if (open) {
      setIndividualMilestoneForm({});
      getIndividualMilestoneDetailsById(setIndividualMilestoneForm, setAlert, {
        universityId,
        milestoneId,
      });
    }
    if (!open) {
      clearAlert();
    }
  }, [open, universityId, milestoneId, setAlert, clearAlert]);

  return (
    <MuiDialog
      open={open}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
      }}
      fullWidth
      hideBackdrop
      disableScrollLock
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          event.stopPropagation();
          handleCancel();
        }
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={individualMilestoneForm}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          updateIndividualMilestone(
            handleCancel,
            setShowSaveConfirmationMsg,
            setReloadAudit,
            setAlert,
            {
              universityId,
              milestoneId,
              values,
            }
          );
        }}
      >
        {(props) => {
          const { values, dirty, isValid, handleChange, handleSubmit } = props;
          return (
            <form>
              <MuiDialogTitle
                id="draggable-dialog-title"
                sx={{ cursor: "move" }}
              >
                {(actionType === "edit" &&
                  t(
                    `${editViewIndividualMilestoneLabels}.editIndividualMilestoneLabel`
                  )) ||
                  t(
                    `${editViewIndividualMilestoneLabels}.viewIndividualMilestoneLabel`
                  )}
              </MuiDialogTitle>
              <MuiDialogContent
                sx={{ overflowY: "hidden", padding: "0px 24px" }}
              >
                {alert.exists && (
                  <MuiGrid container>
                    <Alert />
                  </MuiGrid>
                )}
                <MuiGrid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  paddingBottom={1}
                >
                  <MuiGrid item>
                    <MuiLensIcon
                      fontSize="small"
                      sx={{
                        verticalAlign: "middle",
                        color: SegmentsColor[`${values.category}_LIGHT`],
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography>{values.category}</MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container direction="column" spacing={3}>
                  <MuiGrid item>
                    <MuiTextField
                      id="standard-uncontrolled"
                      label={t(
                        `${editViewIndividualMilestoneLabels}.milestoneDate`
                      )}
                      size="small"
                      value={values.date || ""}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        // Keep the text color black even when disabled
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                        // Ensure solid bottom border when disabled
                        "& .MuiInput-underline.Mui-disabled:before": {
                          borderBottomStyle: "solid", // Ensure solid bottom border when disabled
                        },
                      }}
                      disabled
                    />
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTableContainer
                      component={MuiPaper}
                      sx={{
                        border: "1px solid",
                        borderColor: Theme.palette.grey[400],
                        boxShadow: "unset",
                      }}
                    >
                      <MuiTable aria-label="customized table">
                        <MuiTableHead
                          sx={{
                            background: Theme.palette.primary.light,
                          }}
                        >
                          <MuiTableRow
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            <MuiTableCell
                              align="left"
                              width="32%"
                              sx={{ fontWeight: 600, padding: "15px" }}
                            >
                              {t(
                                `${editViewIndividualMilestoneLabels}.identifier`
                              )}
                            </MuiTableCell>
                            <MuiTableCell
                              align="left"
                              width="20%"
                              sx={{
                                fontWeight: 600,
                              }}
                            >
                              {t(
                                `${editViewIndividualMilestoneLabels}.dailyAccrual`
                              )}
                            </MuiTableCell>
                            <MuiTableCell
                              align="left"
                              width="10%"
                              sx={{ fontWeight: 600 }}
                            >
                              {t(
                                "LeaveAccruedTaken.appointmentSegmentsLabels.maxAccrual"
                              )}
                            </MuiTableCell>
                          </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                          <MuiTableRow>
                            <MuiTableCell
                              align="left"
                              scope="row"
                              sx={{ padding: "15px" }}
                            >
                              <MuiGrid container spacing={1} wrap="nowrap">
                                <MuiGrid component="span" item>
                                  {values.description}
                                </MuiGrid>
                                <MuiGrid component="span" item>
                                  {values.identifier === "RETURN_TO_NORMAL" && (
                                    <MuiTooltip
                                      title={t(
                                        `${editViewIndividualMilestoneLabels}.returnToNormalTooltipText`
                                      )}
                                      placement="right-start"
                                    >
                                      <MuiHelpIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </MuiTooltip>
                                  )}
                                </MuiGrid>
                              </MuiGrid>
                            </MuiTableCell>
                            <MuiTableCell align="left">
                              {formatDecimalValues(values.dailyAccrual)}
                            </MuiTableCell>
                            <MuiTableCell align="left">
                              {formatDecimalValues(values.maxAccrual)}
                            </MuiTableCell>
                          </MuiTableRow>
                        </MuiTableBody>
                      </MuiTable>
                    </MuiTableContainer>
                  </MuiGrid>
                  <MuiGrid item>
                    {(actionType === "edit" && (
                      <MuiTextField
                        id="note"
                        label={t(
                          `${editViewIndividualMilestoneLabels}.individualMilestoneNotes`
                        )}
                        value={values.note || ""}
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    )) || (
                      <MuiTextField
                        label={t(
                          `${editViewIndividualMilestoneLabels}.individualMilestoneNotes`
                        )}
                        size="small"
                        value={values.note || ""}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          // Keep the text color black even when disabled
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                          },
                          // Ensure solid bottom border when disabled
                          "& .MuiInput-underline.Mui-disabled:before": {
                            borderBottomStyle: "solid", // Ensure solid bottom border when disabled
                          },
                        }}
                        disabled
                        fullWidth
                      />
                    )}
                  </MuiGrid>
                </MuiGrid>
              </MuiDialogContent>
              <MuiDialogActions sx={{ padding: "24px" }}>
                <MuiGrid
                  container
                  item
                  xs={6}
                  spacing={2}
                  justifyContent="flex-end"
                  wrap="nowrap"
                >
                  <MuiGrid container item xs={5}>
                    <MuiButton
                      onClick={handleCancel}
                      variant={
                        (actionType === "edit" && "outlined") || "contained"
                      }
                      color="primary"
                      fullWidth
                    >
                      {(actionType === "edit" &&
                        t("globals.form.actionButtons.cancel")) ||
                        t("globals.form.actionButtons.close")}
                    </MuiButton>
                  </MuiGrid>
                  {actionType === "edit" && (
                    <MuiGrid item container xs={5}>
                      <MuiButton
                        variant="contained"
                        disabled={!dirty || !isValid}
                        onClick={() => setShowSaveConfirmationMsg(true)}
                        color="primary"
                        fullWidth
                      >
                        {t("globals.form.actionButtons.save")}
                      </MuiButton>
                      <ConfirmationDialog
                        open={showSaveConfirmationMsg}
                        message={t(
                          `${editViewIndividualMilestoneLabels}.editSaveConfirmationMsg`
                        )}
                        handleCancel={() => setShowSaveConfirmationMsg(false)}
                        handleOk={handleSubmit}
                        okLabel={t("globals.form.actionButtons.yes")}
                        cancelLabel={t("globals.form.actionButtons.no")}
                      />
                    </MuiGrid>
                  )}
                </MuiGrid>
              </MuiDialogActions>
            </form>
          );
        }}
      </Formik>
    </MuiDialog>
  );
};
