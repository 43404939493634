import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { convertDateStrToFLADateStr } from "app/shared/utils";
/** Service Hooks **/
import { getAppointmentsHistory } from "app/services/leaveAccrualTakenService";
import { LeaveSectionNotes } from "./LeaveActions/LeaveSectionNotes";

//Function to create the Appointment History panel detail
export const AppointmentHistory = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  //Call to the appointments history service to return the data
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadAppointments, setReloadAppointments] = useState(true);
  const {
    universityId,
    setAlert,
    clearAlert,
    showLeaveSectionNotesDialog,
    handleChangeLeaveSectionNotesDialog,
    setShowLeaveSectionNotesDialog,
  } = props;
  useEffect(() => {
    reloadAppointments &&
      getAppointmentsHistory(
        setAppointments,
        setLoading,
        setAlert,
        clearAlert,
        universityId
      );
    setReloadAppointments(false);
  }, [universityId, setAlert, clearAlert, reloadAppointments]);

  useEffect(() => {
    appointments && props.setLeaveNotesContent(appointments.leaveNote || "");
    appointments &&
      props.setIsLeaveNoteEditable(appointments?._links?.editLeaveNote);
  }, [appointments, props]);

  // Common string for translation key
  const apptHistoryLabels = "LeaveAccruedTaken.apptHistoryLabels";
  const columnData = [
    {
      id: "rank",
      value: t(`${apptHistoryLabels}.rank`),
      width: "28%",
    },
    {
      id: "line",
      value: t(`${apptHistoryLabels}.line`),
      width: "10%",
    },
    {
      id: "department",
      value: t(`${apptHistoryLabels}.department`),
      tooltip: true,
      width: "20%",
    },
    {
      id: "startDate",
      value: t(`${apptHistoryLabels}.apptStart`),
      width: "12%",
    },
    {
      id: "endDate",
      value: t(`${apptHistoryLabels}.apptEnd`),
      width: "12%",
    },
    {
      id: "apptFte",
      value: t(`${apptHistoryLabels}.fte`),
    },
  ];

  const rowData = [];

  appointments.appointments &&
    appointments.appointments.map((i) =>
      rowData.push({
        rank: { value: i.rank },
        line: { value: i.facultyLine.abbreviation },
        department: { value: i.department && i.department.name },
        startDate: {
          value: convertDateStrToFLADateStr(i.startDate),
        },
        endDate: {
          value: convertDateStrToFLADateStr(i.endDate),
        },
        apptFte: { value: i.apptFte },
      })
    );

  return (
    <>
      {(loading && (
        <MuiGrid container justifyContent="center">
          <MuiCircularProgress />
        </MuiGrid>
      )) || <AccordionSummaryTable columnData={columnData} rowData={rowData} />}
      <LeaveSectionNotes
        open={showLeaveSectionNotesDialog}
        onClose={handleChangeLeaveSectionNotesDialog}
        editLeaveSectionNotes={
          appointments._links && appointments._links.editLeaveNote
        }
        universityId={universityId}
        setShowLeaveSectionNotesDialog={setShowLeaveSectionNotesDialog}
        setReloadAppointments={setReloadAppointments}
        existingLeaveNote={appointments && appointments.leaveNote}
      />
    </>
  );
};
