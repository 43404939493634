import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
/**  Material UI Imports **/
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  Checkbox as MuiCheckbox,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  OutlinedInput as MuiOutlinedInput,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import { Help as MuiHelpIcon } from "@mui/icons-material";
import { Theme } from "common";
/** Service Import */
import { getMilestonesByCategory } from "app/services/individualMilestoneService";

export const IndividualMilestoneAddEditTable = ({
  category,
  setFieldValue,
  handleChange,
  setAlert,
}) => {
  const { t } = useTranslation();
  const individualMilestoneLabel = "LeaveAccruedTaken.individualMilestoneForm";

  const [individualMilestones, setIndividualMilestones] = useState([]);
  const [checkedMilestones, setCheckedMilestones] = useState([]);

  /** Call to service to get all individual milestones as per category */
  useEffect(() => {
    getMilestonesByCategory(setIndividualMilestones, setAlert, category);
    setCheckedMilestones([]);
  }, [category, setAlert]);

  /* Function to format the Decimal Values,
   * if the length of Decimal digits is greater than 3,
   * then round-off the value to 3 decimal places (keeping the trailing zeros),
   * otherwise return the same value
   */
  const formatDecimalValues = (value) =>
    value && value % 1 !== 0 && value.toString().split(".")[1].length > 3
      ? value.toFixed(3)
      : value;
  const [otherIdentifierSelected, setOtherIdentifierSelected] = useState(false);

  const getAccrualCells = (row, otherIdentifierSelected) => {
    if (row.identifier === "OTHER") {
      return (
        <>
          <MuiTableCell align="left" sx={{ padding: 0 }}>
            {otherIdentifierSelected && (
              <MuiOutlinedInput
                margin="dense"
                sx={{ height: "30px", width: "140px" }}
                onChange={(event) => {
                  handleChange(event);
                }}
                inputProps={{ maxLength: 16 }}
                name="dailyAccrual"
              />
            )}
          </MuiTableCell>
          <MuiTableCell align="left" sx={{ padding: 0 }}>
            {otherIdentifierSelected && (
              <MuiOutlinedInput
                margin="dense"
                sx={{ height: "30px", width: "80px" }}
                autoComplete="off"
                inputProps={{ maxLength: 16 }}
                onChange={(event) =>
                  setFieldValue("maxAccrual", event.target.value)
                }
              />
            )}
          </MuiTableCell>
        </>
      );
    } else {
      return (
        <>
          <MuiTableCell align="left" name="dailyAccrual">
            {formatDecimalValues(row.dailyAccrual)}
          </MuiTableCell>
          <MuiTableCell align="left" name="maxAccrual">
            {formatDecimalValues(row.maxAccrual)}
          </MuiTableCell>
        </>
      );
    }
  };

  const handleCheckboxChange = (event, row) => {
    const eventValue = event.target.value;
    const checked = event.target.checked;
    setCheckedMilestones(
      (!checkedMilestones.includes(eventValue) && [eventValue]) || []
    );
    setOtherIdentifierSelected(
      (row.identifier === "OTHER" && checked && true) || false
    );
    setFieldValue("identifier", (checked && row.identifier) || null);
    setFieldValue("description", (checked && row.description) || null);
    setFieldValue("dailyAccrual", (checked && row.dailyAccrual) || null);
    setFieldValue("maxAccrual", (checked && row.maxAccrual) || null);
    getAccrualCells(row, otherIdentifierSelected);
  };

  return (
    <MuiTableContainer
      component={MuiPaper}
      sx={{
        border: "1px solid",
        borderColor: Theme.palette.grey[400],
        boxShadow: "none",
        overflow: "hidden",
      }}
    >
      <MuiTable aria-label="customized table" size="small">
        <MuiTableHead sx={{ background: Theme.palette.primary.light }}>
          <MuiTableRow>
            <MuiTableCell
              align="left"
              sx={{
                fontWeight: 600,
                fontSize: "17px",
                paddingLeft: "12px",
                paddingBottom: "0px",
                borderBottom: "none",
              }}
              colSpan="3"
            >
              {`${t(`${individualMilestoneLabel}.chooseIndividualMilestone`)}*`}
            </MuiTableCell>
          </MuiTableRow>
          <MuiTableRow
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            <MuiTableCell
              align="left"
              sx={{ fontWeight: 600, paddingLeft: "38px" }}
            >
              {t(`${individualMilestoneLabel}.identifier`)}
            </MuiTableCell>
            <MuiTableCell align="left" sx={{ fontWeight: 600 }}>
              {t(`${individualMilestoneLabel}.dailyAccrual`)}
            </MuiTableCell>
            <MuiTableCell align="left" sx={{ fontWeight: 600 }}>
              {t("LeaveAccruedTaken.appointmentSegmentsLabels.maxAccrual")}
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {individualMilestones.map((row, index) => (
            <MuiTableRow
              key={row.description}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <MuiTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ padding: 0 }}
              >
                <MuiGrid container alignItems="baseline" wrap="nowrap">
                  <MuiGrid item component="span">
                    <MuiCheckbox
                      color="primary"
                      checked={checkedMilestones.includes(row.identifier)}
                      disableRipple
                      size="small"
                      value={row.identifier}
                      onChange={(event) => {
                        handleCheckboxChange(event, row);
                        handleChange(event);
                      }}
                      name="identifierSelected"
                    />
                  </MuiGrid>
                  <MuiGrid
                    container
                    item
                    xs={10}
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <MuiGrid component="span" item>
                      {row.description}
                    </MuiGrid>
                    <MuiGrid component="span" item>
                      {/** Show Help Icon & Tooltip when 'Return To Normal' Individual Milestone is selected */}
                      {checkedMilestones.includes(row.identifier) &&
                        row.identifier === "RETURN_TO_NORMAL" && (
                          <MuiTooltip
                            title={t(
                              `${individualMilestoneLabel}.returnToNormalTooltipText`
                            )}
                            placement="right-start"
                          >
                            <MuiHelpIcon color="primary" fontSize="small" />
                          </MuiTooltip>
                        )}
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiTableCell>
              {getAccrualCells(row, otherIdentifierSelected)}
            </MuiTableRow>
          ))}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
