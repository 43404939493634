import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { isValid as isDateValid } from "date-fns";
/**  Material UI Imports **/
import {
  Grid as MuiGrid,
  Radio as MuiRadio,
  Dialog as MuiDialog,
  Button as MuiButton,
  RadioGroup as MuiRadioGroup,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  FormControlLabel as MuiFormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import { AddCircle as MuiAddCircleIcon } from "@mui/icons-material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers-pro";
/** Custom Component **/
import { Alert, useAlerts } from "common";
import { SegmentsColor } from "app/shared/constants";
import { IndividualMilestoneAddEditTable } from "./IndividualMilestoneAddEditTable";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
/** Service Import */
import { saveIndividualMilestone } from "app/services/individualMilestoneService";
import { convertDateStrToFLADateStr } from "app/shared/utils";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";

// **** Form Validation Schema ****
const CreateIndividualMilestoneSchema = Yup.object().shape({
  date: Yup.date().required("Required"),
  identifierSelected: Yup.boolean().test(
    "is-checked",
    "Required",
    (value) => value === true
  ),
  dailyAccrual: Yup.number().nullable(),
  maxAccrual: Yup.number().nullable(),
});

export const CreateIndividualMilestone = (props) => {
  const { t } = useTranslation();
  // To show the error
  const { alert, setAlert, clearAlert } = useAlerts();
  const individualMilestoneLabel = "LeaveAccruedTaken.individualMilestoneForm";
  const globalLabels = "globals.labels";
  const globalFormLabels = "globals.form";
  // State for Dialog
  const [openDialog, setOpenDialog] = useState(false);
  // state to handle Date Picker
  const [selectedDate, setSelectedDate] = useState(null);
  // Events to handle the open and close state of Dialog
  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };

  // Initial Form Values
  const createMilestoneForm = {
    category: t(`${globalLabels}.categorySAB`),
    identifierSelected: false,
    identifier: null,
    description: null,
    dailyAccrual: null,
    maxAccrual: null,
    date: null,
    note: null,
  };
  /** State variables for confirmation messages */
  const [showCancelConfirmationMsg, setShowCancelConfirmationMsg] =
    useState(false);
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] = useState(false);
  /** Call to service on form submit **/
  const handleFormSubmit = (values) => {
    const {
      universityId,
      setReloadAppointmentSegments,
      setReloadAudit,
      setReloadAccrual,
    } = props;
    saveIndividualMilestone(
      setShowSaveConfirmationMsg,
      setOpenDialog,
      setReloadAppointmentSegments,
      setReloadAudit,
      setReloadAccrual,
      setAlert,
      {
        values,
        universityId,
      },
      t
    );
  };
  /** Radio group change handler **/
  const handleRadioChange = (resetForm) => {
    resetForm();
    clearAlert();
    setSelectedDate(null);
  };
  // To clear the error from the Dialog
  useEffect(() => {
    if (!openDialog) {
      clearAlert();
      setSelectedDate(null);
    }
  }, [openDialog, clearAlert]);
  return (
    <>
      <MuiButton variant="contained" color="primary" onClick={handleClickOpen}>
        <MuiAddCircleIcon fontSize="small" />
        &nbsp;&nbsp;{" "}
        {t(`${individualMilestoneLabel}.addIndividualMilestoneLabel`)}
      </MuiButton>
      <MuiDialog
        open={openDialog}
        onClose={handleClose}
        PaperComponent={DraggablePaper}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop
        disableScrollLock
        onClick={(event) => event.stopPropagation()}
        fullWidth
      >
        <MuiDialogTitle
          id="draggable-dialog-title"
          sx={{ cursor: "move", padding: "10px 24px 0px" }}
        >
          {t(`${individualMilestoneLabel}.addIndividualMilestoneLabel`)}
        </MuiDialogTitle>
        <MuiDialogContent sx={{ padding: "8px 24px" }}>
          <Formik
            enableReinitialize
            initialValues={createMilestoneForm}
            validationSchema={CreateIndividualMilestoneSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              handleFormSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                dirty,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
              } = props;
              return (
                <form>
                  <MuiGrid container direction="column" spacing={2}>
                    {alert.exists && (
                      <MuiGrid item>
                        <Alert />
                      </MuiGrid>
                    )}
                    <MuiGrid item>
                      <MuiRadioGroup
                        aria-label="radioGroup"
                        name="category"
                        value={values.category}
                        onChange={(event) => {
                          handleRadioChange(props.resetForm);
                          handleChange(event);
                        }}
                        row
                      >
                        <MuiFormControlLabel
                          value="SAB"
                          control={
                            <MuiRadio
                              sx={{
                                "&.Mui-checked": {
                                  color: SegmentsColor.SAB_LIGHT,
                                },
                              }}
                            />
                          }
                          label={t(`${globalLabels}.categorySAB`)}
                          sx={{ marginRight: "35px" }}
                        />
                        <MuiFormControlLabel
                          value="PDL"
                          control={
                            <MuiRadio
                              sx={{
                                "&.Mui-checked": {
                                  color: SegmentsColor.PDL_LIGHT,
                                },
                              }}
                            />
                          }
                          label={t(`${globalLabels}.categoryPDL`)}
                        />
                      </MuiRadioGroup>
                    </MuiGrid>
                    <MuiGrid item xs={6} padding={0} margin={0}>
                      <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
                        <MuiDatePicker
                          inputVariant="outlined"
                          label={`${t(
                            `${individualMilestoneLabel}.milestoneDate`
                          )}*`}
                          value={selectedDate}
                          name="date"
                          format="yyyy-MM-dd"
                          id="date-picker-inline-milestone"
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                            isDateValid(newValue)
                              ? props.setFieldValue(
                                  "date",
                                  convertDateStrToFLADateStr(newValue)
                                )
                              : props.setFieldValue("date", null);
                          }}
                          onError={(e, value) => {
                            e && props.setErrors({ date: e });
                          }}
                          onBlur={handleBlur}
                          error={values.date && errors.date && true}
                          helperText={
                            values.date &&
                            errors.date &&
                            t(
                              `${globalFormLabels}.dateValidationMessages.invalidDateFormat`
                            )
                          }
                        />
                      </MuiLocalizationProvider>
                    </MuiGrid>
                    <MuiGrid item>
                      <IndividualMilestoneAddEditTable
                        category={values.category}
                        setFieldValue={props.setFieldValue}
                        handleChange={handleChange}
                        setAlert={setAlert}
                      />
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiTextField
                        id="note"
                        label={t(
                          `${individualMilestoneLabel}.individualMilestoneNotes`
                        )}
                        value={values.note || ""}
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        multiline
                      />
                    </MuiGrid>
                    <MuiDialogActions sx={{ padding: "16px 0px 8px" }}>
                      <MuiGrid
                        container
                        item
                        xs={6}
                        spacing={2}
                        justifyContent="flex-end"
                        wrap="nowrap"
                      >
                        <MuiGrid container item xs={5}>
                          <MuiButton
                            onClick={() => {
                              dirty
                                ? setShowCancelConfirmationMsg(true)
                                : setOpenDialog(false);
                            }}
                            variant="outlined"
                            color="primary"
                            fullWidth
                          >
                            {t(`${globalFormLabels}.actionButtons.cancel`)}
                          </MuiButton>
                          <ConfirmationDialog
                            open={showCancelConfirmationMsg}
                            message={t(
                              `${individualMilestoneLabel}.cancelConfirmationMsg`
                            )}
                            handleCancel={() =>
                              setShowCancelConfirmationMsg(false)
                            }
                            handleOk={() => {
                              setShowCancelConfirmationMsg(false);
                              setOpenDialog(false);
                            }}
                            okLabel={t(`${globalFormLabels}.actionButtons.yes`)}
                            cancelLabel={t(
                              `${globalFormLabels}.actionButtons.no`
                            )}
                          />
                        </MuiGrid>
                        <MuiGrid item container xs={5}>
                          <MuiButton
                            variant="contained"
                            disabled={
                              !values.dailyAccrual ||
                              !values.maxAccrual ||
                              !dirty ||
                              !isValid
                            }
                            onClick={() => setShowSaveConfirmationMsg(true)}
                            color="primary"
                            fullWidth
                          >
                            {t(`${globalFormLabels}.actionButtons.save`)}
                          </MuiButton>
                          <ConfirmationDialog
                            open={showSaveConfirmationMsg}
                            message={t(
                              `${individualMilestoneLabel}.saveConfirmationMsg`
                            )}
                            handleCancel={() =>
                              setShowSaveConfirmationMsg(false)
                            }
                            handleOk={handleSubmit}
                            okLabel={t(`${globalFormLabels}.actionButtons.yes`)}
                            cancelLabel={t(
                              `${globalFormLabels}.actionButtons.no`
                            )}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiDialogActions>
                  </MuiGrid>
                </form>
              );
            }}
          </Formik>
        </MuiDialogContent>
      </MuiDialog>
    </>
  );
};
