import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { Info as MuiInfoIcon } from "@mui/icons-material";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { convertUTCDateStrToFLADateTimeStr } from "app/shared/utils";
/** Service Call **/
import { getAudit } from "app/services/leaveAccrualTakenService";

//Function to create the Audit panel
export const Audit = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  //Common string of translation key
  const auditDetailsLabels = "LeaveAccruedTaken.auditLabels";
  const { universityId, reloadAudit, setReloadAudit, setAlert, clearAlert } =
    props;
  const [auditDetails, setAuditDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  /** Call to service to fetch Audit */
  useEffect(() => {
    if (reloadAudit || !auditDetails.length) {
      getAudit(setAuditDetails, setLoading, setAlert, clearAlert, universityId);
      setReloadAudit(false);
    }
  }, [
    universityId,
    reloadAudit,
    setReloadAudit,
    auditDetails,
    setAlert,
    clearAlert,
  ]);

  const columnData = [
    {
      id: "sunetId",
      value: t("globals.labels.sunetID"),
      width: "10%",
    },
    {
      id: "userRole",
      value: t(`${auditDetailsLabels}.role`),
      width: "10%",
    },
    {
      id: "changeDate",
      value: t(`${auditDetailsLabels}.date`),
      width: "12%",
    },
    {
      id: "changeType",
      value: t(`${auditDetailsLabels}.action`),
      width: "8%",
    },
    {
      id: "leaveCategory",
      value: t(`${auditDetailsLabels}.category`),
      width: "8%",
    },
    {
      id: "changeObject",
      value: t(`${auditDetailsLabels}.changeObject`),
      width: "11%",
    },
    {
      id: "additionalInfo",
      width: "1%",
    },
    {
      id: "changeDescription",
      value: t(`${auditDetailsLabels}.description`),
      tooltip: true,
      width: "20%",
    },
  ];

  const rowData = [];

  auditDetails.map((data) =>
    rowData.push({
      sunetId: {
        value: data.userName,
      },
      userRole: {
        value: data.userRole,
      },
      changeDate: {
        value: convertUTCDateStrToFLADateTimeStr(data.changeDate),
      },
      changeType: {
        value: data.changeType,
      },
      leaveCategory: {
        value: data.leaveCategory,
        fontColor: `text${data.leaveCategory}Color`,
      },
      changeObject: {
        value: data.changeObject,
      },
      additionalInfo: {
        value: data.additionalInfo && (
          <MuiIconButton aria-label="edit" sx={{ padding: "0px 12px 0px 0px" }}>
            <MuiTooltip title={data.additionalInfo}>
              <MuiInfoIcon color="primary" fontSize="small" />
            </MuiTooltip>
          </MuiIconButton>
        ),
      },
      changeDescription: {
        value: data.changeDescription,
      },
    })
  );

  return (
    (loading && (
      <MuiGrid container justifyContent="center">
        <MuiCircularProgress />
      </MuiGrid>
    )) || <AccordionSummaryTable columnData={columnData} rowData={rowData} />
  );
};
