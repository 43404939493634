import { useTranslation } from "react-i18next";
import { Grid as MuiGrid, Typography as MuiTypography } from "@mui/material";
import { FilterPanel } from "app/shared/ui/FilterPanel";
import { FacultyLeave } from "./FacultyLeave";
import { FacultyLeaveFilter } from "./FacultyLeaveFilters";

export const FacultyLeavePage = () => {
  const { t } = useTranslation();

  return (
    <MuiGrid container wrap="nowrap" gap="24px">
      <MuiGrid item>
        <FilterPanel>
          <FacultyLeaveFilter />
        </FilterPanel>
      </MuiGrid>
      <MuiGrid
        item
        container
        spacing={2}
        wrap="nowrap"
        direction="column"
        paddingTop="24px"
      >
        <MuiGrid item>
          <MuiTypography variant="h1">{t("FLA.mainView.title")}</MuiTypography>
        </MuiGrid>
        <MuiGrid item>
          <FacultyLeave />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
