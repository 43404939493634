// Wrapper component for the Material UI Alert component. Integrates with the alerts service
// and handles temporary alerts and window scrolling (to reveal alerts at the top of the page body).

import { useEffect, useRef, useState } from "react";
import {
  Collapse as MuiCollapse,
  IconButton as MuiIconButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  Typography as MuiTypography,
} from "@mui/material";
import { Close as MuiCloseIcon } from "@mui/icons-material";
import { Alert as MuiAlert } from "@mui/material";
import { useAlerts } from "./AlertsProvider";

export const Alert = () => {
  const [open, setOpen] = useState(true);
  const { alert, ALERT_TYPE, clearAlert } = useAlerts();
  const timeout = useRef(null);

  const TIMEOUT = 5000; // Display time (in milliseconds) for a temporary alert

  useEffect(() => {
    if (alert.scrollWindow) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    if (alert.isTemporary) {
      timeout.current = setTimeout(() => {
        setOpen(false);
        clearAlert();
      }, TIMEOUT);
    }

    return () => clearTimeout(timeout.current);
  }, [alert.isTemporary, alert.scrollWindow, clearAlert]);

  if (alert.type === ALERT_TYPE.DIALOG)
    return (
      <AlertDialog
        open={open}
        title={"Notification"}
        message={alert.message}
        handleClose={() => {
          setOpen(false);
          clearAlert();
        }}
      />
    );

  return (
    <MuiCollapse in={open}>
      <MuiAlert
        severity={alert.type}
        action={
          <MuiIconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              clearAlert();
            }}
          >
            <MuiCloseIcon fontSize="inherit" />
          </MuiIconButton>
        }
      >
        {alert.message}
      </MuiAlert>
    </MuiCollapse>
  );
};

const AlertDialog = ({ open, handleClose, title, message }) => {
  return (
    <div>
      <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <MuiDialogTitle id="alert-dialog-title">{title}</MuiDialogTitle>
        <MuiDialogContent>
          <MuiTypography id="alert-dialog-description" variant="body2">
            {message}
          </MuiTypography>
        </MuiDialogContent>
        <MuiDialogActions>
          <MuiButton onClick={handleClose} color={"primary"} autoFocus>
            Close
          </MuiButton>
        </MuiDialogActions>
      </MuiDialog>
    </div>
  );
};
