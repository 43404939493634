import axios from "axios";

/* Service to prefetch Line and Department Options */
export const getInitialLineDepartmentOptions = async (
  setLineOptions,
  setDepartmentOptions,
  setPrefetchFiltersError
) => {
  const lineRequest = {
    url: `/facultylines/autocompletes`,
  };
  const departmentRequest = {
    url: `/appointments/departments`,
  };
  const requests = [lineRequest, departmentRequest];
  try {
    setPrefetchFiltersError({
      exist: false,
      message: null,
    });
    var response = await Promise.all(
      requests.map((request) => axios(request).then((response) => response))
    );

    if (response[0].data.count && response[1].data.length) {
      setLineOptions(response[0].data.values);
      setDepartmentOptions(addDepartmentDisplayName(response[1].data));
    }
  } catch (error) {
    setPrefetchFiltersError({
      exist: true,
      message: error.message,
    });
  }
};

/* Service to fetch division filters options. */
export const getDivisionOptionsFromDepartments = async (
  departments,
  setDivisionOptions,
  setDivisionFilterInputValue,
  setPrefetchFiltersError
) => {
  try {
    const response = await axios({
      url: `/appointments/divisions?departments=${departments}`,
    });
    if (response.data.length) {
      setDivisionOptions(addDivisionDisplayName(response.data));
    } else {
      setDivisionOptions([]);
      setDivisionFilterInputValue([]);
    }
  } catch (error) {
    setPrefetchFiltersError({
      exist: true,
      message: error.message,
    });
  }
};

const addDepartmentDisplayName = (departments) => {
  departments.forEach((d) => (d.displayText = `${d.name} (${d.code})`));
  return departments;
};

const addDivisionDisplayName = (divisions) => {
  divisions.forEach((d) => (d.displayText = `${d.name} (${d.code})`));
  return divisions;
};
