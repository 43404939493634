import { useEffect, useState } from "react";
import {
  Grid as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  TextField as MuiTextField,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Alert, useAlerts } from "common";
import { sendLeaveEmailNotification } from "app/services/leaveActionService";
import { EmailBccValue } from "app/shared/constants";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";

export const EmailNotification = (props) => {
  const {
    open,
    onClose,
    emailDetails,
    setReloadLeave,
    universityId,
    actionId,
    setShowEmailNotificationDialog,
  } = props;
  const [toValue, setToValue] = useState([]);
  const [ccValue, setCcValue] = useState([]);
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    if (open) {
      clearAlert();
    }
  }, [open, clearAlert]);

  const handleSubmit = () => {
    sendLeaveEmailNotification(
      universityId,
      actionId,
      toValue,
      ccValue,
      emailDetails,
      setReloadLeave,
      setShowEmailNotificationDialog,
      setAlert
    );
  };
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
      hideBackdrop
      disableScrollLock
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <MuiDialogTitle id="draggable-dialog-title" sx={{ cursor: "move" }}>
        {t(
          "LeaveAccruedTaken.leaveActionForm.emailNotification.emailNotificationTitle"
        )}
      </MuiDialogTitle>
      {alert.exists && <Alert />}
      <MuiDialogContent>
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="to"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.toLabel"
              )}
              fullWidth
              variant={"outlined"}
              required
              onChange={(event) => {
                setToValue(event.target.value.split(","));
              }}
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="cc"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.ccLabel"
              )}
              fullWidth
              variant={"outlined"}
              onChange={(event) => {
                setCcValue(event.target.value.split(","));
              }}
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="bcc"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.bccLabel"
              )}
              fullWidth
              variant={"outlined"}
              value={EmailBccValue}
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="subject"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.subjectLabel"
              )}
              fullWidth
              variant={"outlined"}
              value={emailDetails.subject}
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTextField
              id="body"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.bodyLabel"
              )}
              multiline
              fullWidth
              variant={"outlined"}
              inputProps={{ maxLength: 2000 }}
              value={emailDetails.body}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiGrid container item xs={6} justifyContent="space-evenly">
          <MuiGrid item xs={5}>
            <MuiButton
              variant="outlined"
              color="primary"
              onClick={onClose}
              fullWidth
            >
              {t("globals.form.actionButtons.dismiss")}
            </MuiButton>
          </MuiGrid>
          <MuiGrid item xs={5}>
            <MuiButton
              variant="contained"
              color="primary"
              disabled={toValue.length === 0}
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("globals.form.actionButtons.send")}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiDialogActions>
    </MuiDialog>
  );
};
