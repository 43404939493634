import { useTranslation } from "react-i18next";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  Divider as MuiDivider,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
} from "@mui/material";
import { Warning as MuiWarningIcon } from "@mui/icons-material";
/** Custom Component **/
import { convertDateStrToFLADateStr } from "app/shared/utils";

//Function to create the Current Appointments panel detail
export const CurrentAppointments = ({ facultyDetails }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const currentApptDetailsLabels = "LeaveAccruedTaken.currentApptLabels";

  return (
    (facultyDetails.appointments &&
      facultyDetails.appointments.length > 0 &&
      facultyDetails.appointments.map((row, index) => (
        <div key={index}>
          <MuiGrid container key={index}>
            <MuiGrid container item>
              <MuiTypography color="textPrimary" variant="h2">
                {row.tier}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid container item xs={11}>
              <MuiGrid item xs={5}>
                <MuiGrid container item xs={11}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptFTE`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {row.apptFte}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item xs={11}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.department`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid container item wrap="nowrap" xs={6}>
                    <MuiTooltip
                      title={(row.department && row.department.name) || ""}
                      placement="bottom-start"
                    >
                      <MuiTypography
                        color="textPrimary"
                        fontWeight="600"
                        noWrap
                      >
                        {row.department && row.department.name}
                      </MuiTypography>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item xs={11}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.rank`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item container wrap="nowrap" xs={6}>
                    <MuiTooltip title={row.rank} placement="bottom-start">
                      <MuiTypography
                        color="textPrimary"
                        fontWeight="600"
                        noWrap
                      >
                        {row.rank}
                      </MuiTypography>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item xs={11}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptStart`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {convertDateStrToFLADateStr(row.startDate)}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <MuiGrid container>
                  <MuiGrid item xs={2}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.school`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item container xs={9} spacing={1}>
                    <MuiGrid item>
                      <MuiTypography color="textPrimary" fontWeight="600">
                        {row.school.name}
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item>
                      {!row.schoolOfMedicine && (
                        <MuiTooltip
                          title={t(
                            `${currentApptDetailsLabels}.schoolTooltipText`
                          )}
                          placement="right"
                        >
                          <MuiWarningIcon fontSize="small" fontWeight="600" />
                        </MuiTooltip>
                      )}
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container>
                  <MuiGrid item xs={2}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.division`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid container item xs={8} wrap="nowrap">
                    {row.division && (
                      <MuiTooltip
                        title={row.division.name || ""}
                        placement="bottom-start"
                      >
                        <MuiTypography
                          color="textPrimary"
                          fontWeight="600"
                          noWrap
                        >
                          {row.division.name}
                        </MuiTypography>
                      </MuiTooltip>
                    )}
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container>
                  <MuiGrid item xs={2}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.line`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {row.facultyLine.abbreviation} {row.facultyLine.text}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container>
                  <MuiGrid item xs={2}>
                    <MuiTypography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptEnd`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {convertDateStrToFLADateStr(row.endDate)}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          {index < facultyDetails.appointments.length - 1 && (
            <MuiDivider sx={{ margin: "10px -16px 10px -16px" }} />
          )}
        </div>
      ))) ||
    null
  );
};
