import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
/** MUI Imports */
import {
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Info as MuiInfoIcon,
  ListAlt as MuiListAltIcon,
  Warning as MuiWarningIcon,
} from "@mui/icons-material";
/** Custom Component **/
import { Theme } from "common";
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { CreateEditViewLeaveActions } from "./LeaveActions/CreateEditViewLeaveActions";
import { LeaveRemarksDialog } from "./LeaveActions/LeaveRemarksDialog";
import { LeaveActions, InvalidReasons } from "app/shared/constants";
import {
  formatDecimalValues,
  convertDateStrToFLADateStr,
} from "app/shared/utils";
/** Service Call **/
import { getLeaves } from "app/services/leaveAccrualTakenService";

//Function to create the Leave panel
export const Leave = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const leaveHistoryLabels = "LeaveAccruedTaken.leaveHistoryLabels";
  /* Logic to call the service again, when new data is added from Leave Action Dialog */
  const [leaves, setLeaves] = useState([]);
  const {
    universityId,
    reloadLeave,
    setReloadLeave,
    setReloadAudit,
    setReloadAccrual,
    setAlert,
    clearAlert,
  } = props;
  const [loading, setLoading] = useState(false);
  /** Call to service to fetch Leaves */
  useEffect(() => {
    if (reloadLeave || !leaves.length) {
      getLeaves(setLeaves, setLoading, setAlert, clearAlert, universityId);
      setReloadLeave(false);
    }
  }, [universityId, reloadLeave, setReloadLeave, leaves, clearAlert, setAlert]);
  const [showEditLeaveActionDialog, setShowEditLeaveActionDialog] =
    useState(false);
  const [actionType, setActionType] = useState("");
  const [leaveId, setLeaveId] = useState("");

  const [showLeaveRemarksDialog, setShowLeaveRemarksDialog] = useState(false);
  const [leave, setLeave] = useState({});

  const openLeaveActionDialog = (leaveId, actionType) => {
    setShowEditLeaveActionDialog(true);
    setActionType(actionType);
    setLeaveId(leaveId);
  };

  const openLeaveRemarksDialog = (leave) => {
    setShowLeaveRemarksDialog(true);
    setLeave(leave);
  };

  const formatPercentageOnDuty = (pod) => {
    if (pod == null) {
      return "";
    }
    return `${formatDecimalValues(pod)}%`;
  };

  const formatInvalidReasons = (invalidReasons) => {
    return invalidReasons.map((anObjectMapped, index) => {
      return (
        <div key={index}>
          {t(InvalidReasons[anObjectMapped])}
          <br />
        </div>
      );
    });
  };

  const columnData = [
    {
      id: "title",
      value: t(`${leaveHistoryLabels}.leaveType`),
      width: "20%",
    },
    {
      id: "dataSource",
      value: t(`${leaveHistoryLabels}.leaveDataSource`),
      width: "10%",
    },
    {
      id: "startDate",
      value: t(`${leaveHistoryLabels}.leaveStart`),
      width: "10%",
    },
    {
      id: "endDate",
      value: t(`${leaveHistoryLabels}.leaveEnd`),
      width: "10%",
    },
    {
      id: "percentageOnDuty",
      value: t(`${leaveHistoryLabels}.percOnDuty`),
      width: "15%",
    },
    {
      id: "actionId",
      value: t(`${leaveHistoryLabels}.actionId`),
      width: "8%",
    },
    {
      id: "actionState",
      value: t(`${leaveHistoryLabels}.actionState`),
      width: "14%",
    },
    {
      id: "actionIcons",
      value: t("globals.labels.actions"),
    },
  ];
  const rowData = leaves.map((i) => {
    return {
      title: { value: i.title },
      dataSource: { value: i.dataSource },
      status: { value: "TBD" },
      startDate: {
        value: convertDateStrToFLADateStr(i.startDate),
      },
      endDate: {
        value: convertDateStrToFLADateStr(i.endDate),
        segmentIcon: !i.valid && (
          <MuiTooltip title={formatInvalidReasons(i.invalidReasons)}>
            <MuiWarningIcon
              fontSize="small"
              sx={{ color: Theme.palette.warning.main, marginTop: "5px" }}
            />
          </MuiTooltip>
        ),
        rowReverse: !i.valid && true,
      },
      percentageOnDuty: { value: formatPercentageOnDuty(i.percentageOnDuty) },
      actionId: {
        value: i.actionId,
      },
      actionState: { value: t(LeaveActions[i.actionState]) },
      actionIcons: {
        value: (
          <MuiGrid container gap={1}>
            <MuiGrid item xs={4} lg={2} xl={1}>
              {i.actionId && i._links.editLeave && (
                <MuiIconButton
                  aria-label="edit"
                  onClick={() => openLeaveActionDialog(i.actionId, "edit")}
                >
                  <MuiEditIcon fontSize="small" />
                </MuiIconButton>
              )}
            </MuiGrid>
            <MuiGrid item xs={4} lg={2} xl={1}>
              {i.actionId && i._links.viewLeave && (
                <MuiIconButton
                  aria-label="view"
                  onClick={() => openLeaveActionDialog(i.actionId, "view")}
                >
                  <MuiListAltIcon fontSize="small" />
                </MuiIconButton>
              )}
            </MuiGrid>
            <MuiGrid item xs={4} lg={2} xl={1}>
              {i.remarks && (
                <MuiIconButton
                  aria-label="remarks"
                  onClick={() => openLeaveRemarksDialog(i)}
                >
                  <MuiInfoIcon fontSize="small" />
                </MuiIconButton>
              )}
            </MuiGrid>
          </MuiGrid>
        ),
      },
    };
  });
  return (
    (loading && (
      <MuiGrid container justifyContent="center">
        <MuiCircularProgress />
      </MuiGrid>
    )) || (
      <>
        <AccordionSummaryTable columnData={columnData} rowData={rowData} />
        <CreateEditViewLeaveActions
          actionType={actionType}
          leaveId={leaveId}
          universityId={universityId}
          setShowEditLeaveActionDialog={setShowEditLeaveActionDialog}
          showEditLeaveActionDialog={showEditLeaveActionDialog}
          setReloadLeave={setReloadLeave}
          setReloadAudit={setReloadAudit}
          setReloadAccrual={setReloadAccrual}
        />
        <LeaveRemarksDialog
          setShowDialog={setShowLeaveRemarksDialog}
          showDialog={showLeaveRemarksDialog}
          leave={leave}
        />
      </>
    )
  );
};
