import {
  Grid as MuiGrid,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import { Theme } from "common";
import { SegmentsColor } from "app/shared/constants";

// function to get cell text styles based on row and column
const getCellTextStyle = (row, column) => {
  if (row[column.id]) {
    if (row[column.id]?.fontStyle) {
      return { fontStyle: "italic" };
    } else if (row[column.id]?.fontColor === "textSABColor") {
      return { color: SegmentsColor["SAB_DARK"] };
    } else if (row[column.id]?.fontColor === "textPDLColor") {
      return { color: SegmentsColor["PDL_DARK"] };
    }
  } else {
    return {};
  }
};

export const AccordionSummaryTable = (props) => {
  return (
    <MuiTableContainer>
      <MuiTable size="small">
        <MuiTableHead>
          <MuiTableRow>
            {props.columnData.map((row, index) => (
              <MuiTableCell
                align="left"
                key={index}
                width={row.width}
                sx={{
                  border: "none",
                  fontWeight: 600,
                  fontSize: "17px",
                  padding: "8px",
                }}
              >
                {row.tooltipHeader || row.icon ? (
                  <MuiGrid container spacing={1} direction="row">
                    {row.icon ? (
                      <>
                        <MuiGrid item xs={8}>
                          {row.value}
                        </MuiGrid>
                        <MuiGrid item xs={2}>
                          {row.icon}
                        </MuiGrid>
                      </>
                    ) : (
                      <>
                        <MuiGrid item>{row.value}</MuiGrid>
                        <MuiGrid item xs={(row.tooltipValue && 4) || 2}>
                          <MuiTooltip
                            title={row.tooltipValue ?? row.tooltipHeader}
                            placement="bottom-start"
                          >
                            {row.tooltipIcon ?? ""}
                          </MuiTooltip>
                        </MuiGrid>
                      </>
                    )}
                  </MuiGrid>
                ) : (
                  row.value
                )}
              </MuiTableCell>
            ))}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {props.rowData.map((row, index) => (
            <MuiTableRow
              key={index}
              sx={{
                background: row["rowColor"]?.value && Theme.palette.grey[300],
              }}
            >
              {props.columnData.map((column, key) => (
                <MuiTableCell
                  align="left"
                  width={column.width}
                  key={key}
                  sx={{
                    border: "none",
                    padding: "8px",
                  }}
                >
                  <MuiGrid container alignItems="flex-end" spacing={2}>
                    {row[column.id] &&
                      row[column.id].segmentIcon &&
                      !row[column.id].rowReverse && (
                        <MuiGrid item>{row[column.id].segmentIcon}</MuiGrid>
                      )}
                    <MuiGrid item sx={{ overflow: "hidden" }} xs>
                      <MuiTooltip
                        title={column.tooltip ? row[column.id].value : ""}
                        placement="bottom-start"
                      >
                        <MuiTypography
                          noWrap
                          sx={getCellTextStyle(row, column)}
                          // use "span" as root node (instead of 'p') to avoid warning "<div> cannot appear as a descendant of <p>"
                          // see: https://stackoverflow.com/questions/41928567/div-cannot-appear-as-a-descendant-of-p
                          component="span"
                        >
                          {row[column.id] && row[column.id].value}
                        </MuiTypography>
                      </MuiTooltip>
                    </MuiGrid>
                    {row[column.id] &&
                      row[column.id].segmentIcon &&
                      row[column.id].rowReverse && (
                        <MuiGrid item>{row[column.id].segmentIcon}</MuiGrid>
                      )}
                  </MuiGrid>
                </MuiTableCell>
              ))}
            </MuiTableRow>
          ))}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
