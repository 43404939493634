import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const ConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const { open, title, message, okLabel, cancelLabel, handleCancel, handleOk } =
    props;

  return (
    <MuiDialog open={open}>
      <MuiDialogTitle id="alert-dialog-title">{title || ""}</MuiDialogTitle>
      <MuiDialogContent
        sx={{
          display: message ? "block" : "none",
        }}
      >
        <MuiDialogContentText id="alert-dialog-description">
          {message || ""}
        </MuiDialogContentText>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleCancel} color="primary">
          {cancelLabel ?? t("globals.form.actionButtons.cancel")}
        </MuiButton>
        <MuiButton onClick={handleOk} color="primary">
          {okLabel ?? t("globals.form.actionButtons.ok")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
