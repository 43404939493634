import { AccessDenied, useUsers } from "common";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { FacultyLeaveProvider } from "app/services/facultyLeaveContext";
import { FacultyLeavePage } from "app/components/FacultyLeave/FacultyLeavePage";
import { LeaveAccruedTaken } from "app/components/LeaveAccruedTaken/LeaveAccruedTaken";

export const AppRoutes = () => {
  const { accessDenied, currentUser } = useUsers();
  const { t } = useTranslation();

  if (accessDenied || !currentUser.permissions.VIEW_FACULTY) {
    return <AccessDenied supportEmail={t("app.supportEmail")} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <FacultyLeaveProvider>
            <FacultyLeavePage />
          </FacultyLeaveProvider>
        }
      />
      <Route
        path="/faculty"
        element={
          <FacultyLeaveProvider>
            <FacultyLeavePage />
          </FacultyLeaveProvider>
        }
      />
      <Route
        path="/faculty/:universityId"
        element={
          <FacultyLeaveProvider>
            <LeaveAccruedTaken />
          </FacultyLeaveProvider>
        }
      />
    </Routes>
  );
};
