import {
  Grid as MuiGrid,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
} from "@mui/material";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";
import { useTranslation } from "react-i18next";

export const LeaveRemarksDialog = (props) => {
  const { t } = useTranslation();
  const { setShowDialog, showDialog, leave } = props;

  function renderTitle(leave) {
    return (
      <span className="selectable">
        {leave.title} - {leave.dataSource} - {leave.startDate}&ndash;
        {leave.endDate}
        {leave.percentageOnDuty ? ` - ${leave.percentageOnDuty}%` : ""}
      </span>
    );
  }

  function renderContent(leave) {
    return <span className="selectable">{`${leave.remarks}`}</span>;
  }

  return (
    <MuiDialog
      open={showDialog}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      hideBackdrop
      disableScrollLock
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          event.stopPropagation();
          setShowDialog(false);
        }
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxHeight: "100%",
            maxWidth: "720px",
          },
        },
      }}
    >
      <MuiDialogTitle
        id="draggable-dialog-title"
        sx={{ cursor: "move", paddingBottom: "0px" }}
      >
        {renderTitle(leave)}
      </MuiDialogTitle>
      <MuiDialogContent sx={{ padding: "0px 24px 16px 26px" }}>
        <MuiDialogContentText>{renderContent(leave)}</MuiDialogContentText>
        <MuiDialogActions sx={{ padding: "16px 0px 0px" }}>
          <MuiGrid
            container
            item
            xs={8}
            spacing={2}
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <MuiGrid item container xs={6}>
              <MuiButton
                fullWidth
                variant="contained"
                onClick={() => setShowDialog(false)}
                color="primary"
              >
                {t("globals.form.actionButtons.close")}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiDialogActions>
      </MuiDialogContent>
    </MuiDialog>
  );
};
