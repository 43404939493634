import { DevelopmentInfoContainer } from "./DevelopmentInfo.styles";

export const DevelopmentInfo = () => {
  const deploymentServerName = window.DEPLOY_ENV;

  return deploymentServerName === "prod" ? null : (
    <DevelopmentInfoContainer>
      <div className="development-server-name">
        {deploymentServerName?.toUpperCase()} environment
      </div>
    </DevelopmentInfoContainer>
  );
};
