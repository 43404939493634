import axios from "axios";

export const getMilestonesByCategory = async (
  setIndividualMilestones,
  setAlert,
  category
) => {
  try {
    const response = await axios({
      url: `/milestones/${category}`,
    });
    setIndividualMilestones(response.data);
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};

export const saveIndividualMilestone = async (
  setShowSaveConfirmationMsg,
  setOpenDialog,
  setReloadAppointmentSegments,
  setReloadAudit,
  setReloadAccrual,
  setAlert,
  { values, universityId },
  t
) => {
  try {
    const data = {
      category: values.category,
      date: values.date,
      identifier: values.identifier,
      description: values.description,
      dailyAccrual: values.dailyAccrual,
      maxAccrual: values.maxAccrual,
      note: values.note,
    };
    const response = await axios({
      url: `/faculty/${universityId}/individual-milestones`,
      method: "post",
      data,
    });
    setReloadAppointmentSegments(response.data && true);
    setReloadAudit(response.data && true);
    setReloadAccrual(response.data && true);
    setOpenDialog(false);
  } catch (error) {
    const errorMessage = "LeaveAccruedTaken.appointmentSegmentsLabels.messages";
    const [{ name }] = error.response && error.response.data.detail.properties;
    if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
      setAlert("error", t(`${errorMessage}.duplicateMilestone`), false, false);
    } else if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      name === "Category"
    ) {
      setAlert("error", t(`${errorMessage}.incorrectLeaveType`), false, false);
    } else if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      name === "milestone.Date"
    ) {
      setAlert("error", t(`${errorMessage}.noAccrualPeriod`), false, false);
    } else {
      setAlert("error", error.message, false, false);
    }
  } finally {
    setShowSaveConfirmationMsg(false);
  }
};

export const getIndividualMilestoneDetailsById = async (
  setIndividualMilestoneForm,
  setAlert,
  { universityId, milestoneId }
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/individual-milestones/${milestoneId}`,
    });
    setIndividualMilestoneForm(response.data);
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};

export const updateIndividualMilestone = async (
  handleCancel,
  setShowSaveConfirmationMsg,
  setReloadAudit,
  setAlert,
  { universityId, milestoneId, values }
) => {
  try {
    const response = await axios.patch(
      `/faculty/${universityId}/individual-milestones/${milestoneId}`,
      {
        note: values.note,
      }
    );
    setShowSaveConfirmationMsg(response.data && false);
    setReloadAudit(response.data && true);
    handleCancel();
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};

export const deleteIndividualMilestone = async (
  setReloadAppointmentSegments,
  setReloadAudit,
  setReloadAccrual,
  setShowDeleteConfirmationMsg,
  setAlert,
  clearAlert,
  { universityId, milestoneId }
) => {
  try {
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/individual-milestones/${milestoneId}`,
      method: "delete",
    });
    setReloadAppointmentSegments(response.status === 200 && true);
    setReloadAudit(response.status === 200 && true);
    setReloadAccrual(response.status === 200 && true);
    setShowDeleteConfirmationMsg(false);
  } catch (error) {
    setAlert("error", error.message, false, false);
    setShowDeleteConfirmationMsg(false);
    window.scrollTo(0, 0);
  }
};
