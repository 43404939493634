import { Theme } from "common";
import { NumericFormat } from "react-number-format";
import { formatDecimalValues } from "app/shared/utils";

/* Function to format the Negative Values */
export const FormattedNumber = (value) => {
  return (
    <NumericFormat
      value={
        (value < 0 && Math.abs(formatDecimalValues(value))) ||
        formatDecimalValues(value)
      }
      style={{ color: value < 0 && Theme.palette.error.main }}
      displayType="text"
      prefix={value < 0 ? "(" : ""}
      suffix={value < 0 ? ")" : ""}
    />
  );
};
