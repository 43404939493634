import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAlerts } from "common";
/** Material UI Imports **/
import {
  Table as MuiTable,
  Paper as MuiPaper,
  Tooltip as MuiTooltip,
  Checkbox as MuiCheckbox,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { ListAlt as MuiListAltIcon } from "@mui/icons-material";
import { convertDateStrToFLADateStr } from "app/shared/utils";
import { getFacultyLeaveList } from "app/services/facultyLeaveService";
import { useFacultyLeaveContext } from "app/services/facultyLeaveContext";
import { FacultyLeaveTableHead } from "./FacultyLeaveTableHead";

export const FacultyLeave = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setAlert, clearAlert } = useAlerts();
  const { facultyLeaveFiltersPreference, pagination, setPagination } =
    useFacultyLeaveContext();

  const [checked, setChecked] = useState([]);
  const [facultyLeaveList, setFacultyLeaveList] = useState({});
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  useEffect(() => {
    getFacultyLeaveList(
      setFacultyLeaveList,
      setLoading,
      facultyLeaveFiltersPreference,
      pagination.page,
      rowsPerPage,
      orderBy,
      order,
      navigate,
      setAlert,
      clearAlert,
      location
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facultyLeaveFiltersPreference,
    pagination.page,
    rowsPerPage,
    orderBy,
    order,
    setAlert,
    clearAlert,
  ]);

  const handleCheckAll = (checkedAll) => {
    let checkAll = facultyLeaveList.values.map((data, index) => {
      return index;
    });
    setChecked(checkedAll ? checkAll : []);
  };

  const handleCheck = (event) => {
    let eventValue = parseInt(event.target.value);
    setChecked(
      checked.includes(eventValue)
        ? checked.filter((c) => c !== eventValue)
        : [...checked, eventValue]
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPagination({
      ...pagination,
      page: 0,
    });
  };
  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable aria-label="FacultyLeaveTable" stickyHeader>
        <FacultyLeaveTableHead
          order={order}
          orderBy={orderBy}
          handleCheckAll={handleCheckAll}
          onRequestSort={handleRequestSort}
        />
        <MuiTableBody>
          {loading && (
            <MuiTableRow>
              <MuiTableCell colSpan="10" align="center">
                <MuiCircularProgress />
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!loading &&
            facultyLeaveList?.values?.map((row, index) => (
              <MuiTableRow key={index}>
                <MuiTableCell align="left" sx={{ display: "none" }}>
                  <MuiCheckbox
                    checked={checked.includes(index)}
                    disableRipple
                    size="medium"
                    onChange={handleCheck}
                    value={index}
                    color="primary"
                  />
                </MuiTableCell>
                <MuiTableCell align="left">
                  <MuiTooltip
                    title={
                      <>
                        <MuiTypography variant="body2">
                          {t("FLA.mainView.list.tableColumns.name")}
                          {": "}
                          {row.primaryName}
                        </MuiTypography>
                        <MuiTypography variant="body2">
                          {t(
                            "FLA.mainView.list.tooltips.name.text.universityID"
                          )}
                          {": "}
                          {row.universityId}
                        </MuiTypography>
                        <MuiTypography variant="body2">
                          {t("FLA.mainView.list.tooltips.name.text.sunetID")}
                          {": "}
                          {row.sunetId}
                        </MuiTypography>
                      </>
                    }
                    placement="bottom-start"
                  >
                    <Link
                      to={{
                        pathname:
                          "/faculty/" +
                          new URL(row._links.self.href).pathname
                            .split("/")
                            .pop(),
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      {row.primaryName}
                    </Link>
                  </MuiTooltip>
                </MuiTableCell>
                <MuiTooltip title={row.title}>
                  <MuiTableCell align="left">{row.title}</MuiTableCell>
                </MuiTooltip>
                <MuiTooltip title={row.facultyLine}>
                  <MuiTableCell align="left">{row.facultyLine}</MuiTableCell>
                </MuiTooltip>
                <MuiTooltip title={row?.department?.name}>
                  <MuiTableCell align="left">
                    {row?.department?.name}
                  </MuiTableCell>
                </MuiTooltip>
                <MuiTableCell align="left">
                  {row.startDate && convertDateStrToFLADateStr(row.startDate)}
                </MuiTableCell>
                <MuiTableCell align="left">
                  {row.endDate && convertDateStrToFLADateStr(row.endDate)}
                </MuiTableCell>
                <MuiTableCell align="left">{row.activeFlag}</MuiTableCell>
                <MuiTableCell align="left">
                  <MuiTooltip
                    title={t("FLA.mainView.list.tooltips.actions.text")}
                  >
                    <Link
                      to={{
                        pathname:
                          "/faculty/" +
                          new URL(row._links.self.href).pathname
                            .split("/")
                            .pop(),
                      }}
                    >
                      <MuiListAltIcon fontSize="small" />
                    </Link>
                  </MuiTooltip>
                </MuiTableCell>
              </MuiTableRow>
            ))}
          {!loading && !facultyLeaveList.totalCount && (
            <MuiTableRow>
              <MuiTableCell colSpan="10">
                <MuiTypography
                  variant="h6"
                  color="inherit"
                  align="center"
                  paragraph={false}
                >
                  {t("globals.list.messages.noData")}
                </MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </MuiTableBody>
      </MuiTable>
      <MuiTablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={facultyLeaveList.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MuiTableContainer>
  );
};
