//ENUM values for leave types
export const LeaveTypes = {
  SAB: "LeaveAccruedTaken.appointmentSegmentsLabels.SAB",
  PDL: "LeaveAccruedTaken.appointmentSegmentsLabels.PDL",
  NA: "LeaveAccruedTaken.appointmentSegmentsLabels.NA",
};

//ENUM values for segments color
export const SegmentsColor = {
  SAB_LIGHT: "#BE8DDA",
  SAB_DARK: "#7A5BB1",
  PDL_LIGHT: "#7ADFA9",
  PDL_DARK: "#009779",
  NA_LIGHT: "#CCCCCC",
  DEFAULT: "#666666",
};

//ENUM values for status
export const Status = {
  Active: "FLA.filterPanel.options.activeInactive.active",
  Inactive: "FLA.filterPanel.options.activeInactive.inactive",
};

//Enum value for DefaultDataSource
export const DefaultDataSource = "FL";

//ENUM values for Leave Actions
export const LeaveActions = {
  DRAFT: "LeaveAccruedTaken.leaveActionForm.stateLabels.draftState",
  DEPT_OAA_REVIEW:
    "LeaveAccruedTaken.leaveActionForm.stateLabels.deptOaaReviewState",
  DEPT_OAA_DENIED:
    "LeaveAccruedTaken.leaveActionForm.stateLabels.deptOaaDeniedState",
  CENTRAL_OFFICES_REVIEW:
    "LeaveAccruedTaken.leaveActionForm.stateLabels.centralOfficesReviewState",
  FINALLY_APPROVED:
    "LeaveAccruedTaken.leaveActionForm.stateLabels.finallyApprovedState",
  FINALLY_DENIED:
    "LeaveAccruedTaken.leaveActionForm.stateLabels.finallyDeniedState",
  WITHDRAWN: "LeaveAccruedTaken.leaveActionForm.stateLabels.withdrawnState",
};

export const InvalidReasons = {
  STARTDATE_GREATER_THAN_ENDDATE:
    "LeaveAccruedTaken.leaveHistoryLabels.startDateGreaterThanEndDateWarning",
};

// ENUM values for Leave Actions Type
export const LeaveActionsType = {
  PDL: "PDL",
  SAB: "SAB",
  LOA: "LOA",
  LWOS: "LWOS",
};

export const EmailBccValue = [`som-leaves@stanford.edu`];

export const AdjustmentType = {
  ADD: "ADD",
  SUBTRACT: "SUBTRACT",
};

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500;
export const AUTOCOMPLETE_MINIMUM_CHARS = 3;
